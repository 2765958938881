// src/store/slices/employeeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

// Async thunk to fetch employees
export const fetchEmployees = createAsyncThunk('employees/fetchEmployees', async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${config.inxOmsApiUrl}/hrms/api/employees/`, {
        headers: { Authorization: `Token ${token}` }
    });
    return response.data;
});

const employeeSlice = createSlice({
    name: 'employees',
    initialState: {
        employees: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployees.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEmployees.fulfilled, (state, action) => {
                state.employees = action.payload;
                state.loading = false;
            })
            .addCase(fetchEmployees.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default employeeSlice.reducer;
