// src/pages/StatesList.js
import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import StatesTable from './StatesTable';
import {fetchStatesApi} from "./statesApi";

const { Search } = Input;

const StatesList = () => {
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [searchTerm, setSearchTerm] = useState('');
    const user = JSON.parse(localStorage.getItem('user')); // Retrieve user data
    const userRole = user?.user_role; // Extract user role

    // Fetch data from API
    const fetchStates = async (page = 1, search = '') => {
        setLoading(true);
        try {
            const data = await fetchStatesApi(page, search);
            setStates(data.results);
            setPagination((prev) => ({
                ...prev,
                total: data.count,
                current: page,
            }));
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStates(pagination.current, searchTerm);
    }, [pagination.current, searchTerm]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPagination((prev) => ({ ...prev, current: 1 }));
    };

    const handleTableChange = (pagination) => {
        setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    return (
        <div style={{ padding: '24px' }}>
            <h2>States List</h2>
            <Search
                placeholder="Search by state name"
                onSearch={handleSearch}
                style={{ marginBottom: '16px', width: '300px' }}
                enterButton
            />
            <StatesTable
                data={states}
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
                onTableChange={handleTableChange}
            />
        </div>
    );
};

export default StatesList;
