// src/api/saleOrdersApi.js
import axios from 'axios';
import config from "../../config";
import {getAuthToken, getRoleBasedFilters} from "../../utils/authUtils";

// Fetch sales orders with pagination, search, and filters
export const fetchSaleOrdersApi = async (
    page = 1,
    status = '',
    company = '',
    branch = '',
    mobileNumber = '',
    customerName = '',
    orderNumber = ''
) => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/list`, {
            headers: {Authorization: `Token ${token}`},
            params: {
                page,
                status: status || undefined,   // Pass only if status is not empty
                company: company || undefined, // Pass only if company is not empty
                branch: branch || undefined,   // Pass only if branch is not empty
                mobile_number: mobileNumber || undefined,
                customer_name: customerName || undefined,
                order_number: orderNumber || undefined,
                ...roleFilters,
            },
        });
        console.log("Response", response);
        return response.data;
    } catch (error) {
        throw new Error('Failed to load sales orders');
    }
};

// Fetch draft and rejected sale orders
export const fetchDraftOrdersApi = async (page = 1, search = '') => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/list`, {
            headers: {Authorization: `Token ${token}`},
            params: {
                page,
                search,
                status: ['draft', 'rejected'],
                ...roleFilters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load draft orders');
    }
};

// Fetch ready to execute sale orders
export const fetchReadyToExecuteOrdersApi = async (page = 1, search = '') => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/list`, {
            headers: {Authorization: `Token ${token}`},
            params: {
                page,
                search,
                status: ['ready_to_execute'],
                ...roleFilters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load ready to execute orders');
    }
};

// Fetch in process sale orders
export const fetchInProcessOrdersApi = async (page = 1, search = '') => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/list`, {
            headers: {Authorization: `Token ${token}`},
            params: {
                page,
                search,
                status: ['executed', 'ready_to_invoice', 'ready_to_dispatch', 'dispatched'],
                ...roleFilters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load in process orders');
    }
};

// Fetch closed sale orders
export const fetchClosedOrdersApi = async (page = 1, search = '') => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/list`, {
            headers: {Authorization: `Token ${token}`},
            params: {
                page,
                search,
                status: ['closed', 'cancelled'],
                ...roleFilters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load closed orders');
    }
};

// Fetch sale order details by ID
export const fetchSaleOrderDetailsApi = async (orderId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/${orderId}/`, {
            headers: {Authorization: `Token ${token}`},
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load sale order details');
    }
};

// Fetch assigned serial numbers for a specific sale order item
export const fetchSerialNumbersApi = async (orderItemId) => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${config.inxOmsApiUrl}/sales/api/sale-orders/assign-serial-numbers/${orderItemId}`, {
        headers: {Authorization: `Token ${token}`},
    });
    return response.data;
};


export const createSaleOrderApi = async (orderData) => {
    console.log("Submitted Order Data: ", orderData);
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        // Make a POST request to the create sale order endpoint
        const response = await axios.post(`${config.inxOmsApiUrl}/sales/api/sale-orders/`, orderData, {
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
            },
        });

        // Return the response data on success
        return response.data;
    } catch (error) {
        console.log('Failed: ', error);
        // Handle validation errors and return the error response
        if (error.response && error.response.data) {
            throw error.response.data; // Throw the backend error for handling in SalesPage
        } else {
            throw new Error('Network error: Failed to create sale order');
        }
    }
}

export const updateSaleOrderApi = async (orderId, orderData) => {
    console.log("Updating Order Data: ", orderData);
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        // Make a PUT request to the update sale order endpoint
        const response = await axios.patch(`${config.inxOmsApiUrl}/sales/api/sale-orders/${orderId}/`, orderData, {
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
            },
        });

        // Return the response data on success
        return response.data;
    } catch (error) {
        console.error('Update failed:', error);

        // Handle validation errors and return the error response
        if (error.response && error.response.data) {
            throw error.response.data; // Throw the backend error for handling in SalesPage
        } else {
            throw new Error('Network error: Failed to update sale order');
        }
    }
};

// API to confirm a sale order
export const confirmSaleOrderApi = async (orderId) => {
    const token = localStorage.getItem('token');
    const response = await axios.patch(
        `${config.inxOmsApiUrl}/sales/api/sale-orders/${orderId}/submit/`,
        {},
        {
            headers: {Authorization: `Token ${token}`},
        }
    );
    return response.data;
};

// API to execute a sale order
export const executeSaleOrderApi = async (orderId) => {
    const token = localStorage.getItem('token');
    const response = await axios.patch(
        `${config.inxOmsApiUrl}/sales/api/sale-orders/${orderId}/execute/`,
        {},
        {
            headers: {Authorization: `Token ${token}`},
        }
    );
    return response.data;
};



export const approveOrRejectSaleOrderApi = async (orderId, action, rejectionReason = '') => {
    const token = getAuthToken();
    const response = await axios.post(
        `${config.inxOmsApiUrl}/sales/api/sale-orders/${orderId}/approve-or-reject/`,
        {action, rejection_reason: rejectionReason},
        {
            headers: {Authorization: `Token ${token}`},
        }
    );
    return response.data;
};