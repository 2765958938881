import React from 'react';
import {Button, Card, Col, Image, Row, Space, Typography} from 'antd';
import {ShoppingCartOutlined} from '@ant-design/icons';
import './ProductCardList.css';
import {calculateDiscountPercentage, formatRupee, truncateText,} from '../../utils/utils';

const {Text} = Typography;

const ProductCardList = ({products, onAddToCart, onViewDetails}) => {
    const defaultImage = '/photo-coming-soon.jpg';

    return (
        <Row gutter={[0, 0]} style={{margin: 0}}>
            {products.map((product) => {
                const discount =
                    product.mrp && product.ticket_price
                        ? calculateDiscountPercentage(product.mrp, product.ticket_price)
                        : null;

                return (
                    <Col
                        key={product.item_id}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        style={{padding: 4, margin: 0}}
                    >
                        <Card
                            hoverable
                            style={{
                                borderRadius: '4px',
                                overflow: 'hidden',
                                padding: 0,
                                margin: 0,
                            }}
                            className="custom-card"
                        >
                            <div style={{display: 'flex', width: '100%'}}>
                                {/* Product Image */}
                                <div
                                    style={{
                                        flex: '0 0 100px',
                                        backgroundColor: '#f5f5f5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 0,
                                    }}
                                >
                                    <Image
                                        alt={product.item_name}
                                        src={
                                            product.media?.length > 0 && product.media[0].image
                                                ? product.media[0].image
                                                : defaultImage
                                        }
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            maxHeight: '100px',
                                            objectFit: 'cover',
                                            borderRadius: '4px',
                                        }}
                                    />
                                </div>

                                {/* Product Details */}
                                <div style={{flex: 1, padding: '8px'}}>
                                    {/* Item Code and Brand */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '4px',
                                        }}
                                    >
                                        <Text
                                            strong
                                            style={{
                                                color: '#1890ff',
                                                cursor: 'pointer',
                                                margin: 0,
                                                fontSize: '14px',
                                            }}
                                            onClick={() => onViewDetails(product)}
                                        >
                                            {truncateText(product.item_code, 15)}
                                        </Text>
                                        <Text
                                            type="secondary"
                                            style={{whiteSpace: 'nowrap', fontSize: '12px', margin: 0}}
                                        >
                                            {truncateText(product.brand_name || 'N/A', 10)}
                                        </Text>
                                    </div>

                                    {/* Product Name */}
                                    <Text type="secondary" style={{fontSize: '13px', margin: 0}}>
                                        {truncateText(product.item_name, 25)}
                                    </Text>

                                    {/* Pricing and Add to Cart */}
                                    <div style={{marginTop: '6px'}}>
                                        <Space
                                            size="small"
                                            style={{display: 'flex', justifyContent: 'space-between'}}
                                        >
                                            <div>
                                                <Space size="small">
                                                    {product.mrp && (
                                                        <Text
                                                            delete
                                                            type="secondary"
                                                            style={{fontSize: '12px', margin: 0}}
                                                        >
                                                            {formatRupee(product.mrp)}
                                                        </Text>
                                                    )}
                                                    {product.ticket_price && (
                                                        <Text
                                                            strong
                                                            style={{color: '#4caf50', fontSize: '13px', margin: 0}}
                                                        >
                                                            {formatRupee(product.ticket_price)}
                                                        </Text>
                                                    )}
                                                    {discount && (
                                                        <Text
                                                            style={{color: '#4caf50', fontSize: '12px', margin: 0}}
                                                        >
                                                            ({`${discount}% off`})
                                                        </Text>
                                                    )}
                                                </Space>
                                            </div>
                                            <Button
                                                type="primary"
                                                icon={<ShoppingCartOutlined/>}
                                                size="middle"
                                                style={{
                                                    backgroundColor: '#4caf50',
                                                    borderColor: '#4caf50',
                                                    color: '#fff',
                                                    borderRadius: '4px',
                                                    margin: 0,
                                                }}
                                                onClick={() => onAddToCart(product)}
                                            />
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

export default ProductCardList;
