import React, {useEffect, useState, useImperativeHandle, forwardRef} from 'react';
import {Button, Select, Input, Tag, Row, Col} from 'antd';
import {ClearOutlined, FilterOutlined, SearchOutlined} from '@ant-design/icons';
import PurchaseReceiptTable from './PurchaseReceiptTable';
import {fetchPurchaseReceiptsApi} from './procurementApi';
import {fetchCompanies, selectCompanies} from '../../store/slices/companyBranchSlice';
import {useDispatch, useSelector} from 'react-redux';

const {Option} = Select;

const PurchaseReceiptsList = forwardRef(({onViewDetails, setErrorMessage}, ref) => {
    const dispatch = useDispatch();
    const {companies, loading: companiesLoading} = useSelector((state) => state.companyBranch);


    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});

    const [filters, setFilters] = useState({
        searchType: 'id', // Default to Order Number
        searchTerm: '',
        type: 'recipient', // Default to Recipient
        company: '',
        branch: '',
        status: '',
    });

    const [appliedFilters, setAppliedFilters] = useState({...filters}); // Store applied filters
    const [branches, setBranches] = useState([]);

    // Fetch companies on component mount
    useEffect(() => {
        if (!companies || companies.length === 0) {
            dispatch(fetchCompanies());
        }
    }, [dispatch, companies]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === filters.company);
        setBranches(company ? company.branches : []);
    }, [filters.company, companies]);

    // Fetch purchase receipts when filters or pagination change
    useEffect(() => {
        fetchReceipts(pagination.current);
    }, [pagination.current, appliedFilters]); // Use appliedFilters to trigger data fetching

    const fetchReceipts = async (page = 1) => {
        setLoading(true);
        try {
            const data = await fetchPurchaseReceiptsApi({
                page,
                searchType: appliedFilters.searchType,
                searchTerm: appliedFilters.searchTerm,
                type: appliedFilters.type,
                company: appliedFilters.company,
                branch: appliedFilters.branch,
                status: appliedFilters.status,
            });
            setReceipts(data.results);
            setPagination({...pagination, total: data.count});
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleApplyFilters = () => {
        setAppliedFilters(filters); // Update applied filters
        setPagination({...pagination, current: 1}); // Reset pagination
    };

    const handleClearFilters = () => {
        const clearedFilters = {
            searchType: 'id',
            searchTerm: '',
            type: 'recipient',
            company: '',
            branch: '',
            status: '',
        };
        setFilters(clearedFilters);
        setAppliedFilters(clearedFilters);
        setPagination({current: 1, pageSize: 10});
    };

    // Expose the refresh function to the parent component
    useImperativeHandle(ref, () => ({
        refreshReceipts: () => {
            fetchReceipts(pagination.current);
        },
    }));

    const statusDisplayMap = {
        draft: 'Draft',
        received: 'Received',
        cancelled: 'Cancelled',
        closed: 'Closed',
    };

    const searchTypeDisplayMap = {
        "id": "PO ID",
        "po_number": "PO Number",
        "invoice_number": "Invoice Number",
    };

    const displayedFilters = (
        <>
            {appliedFilters.searchTerm && (
                <Tag>
                    {appliedFilters.searchType === 'id' && `PO ID: ${appliedFilters.searchTerm}`}
                    {appliedFilters.searchType === 'po_number' && `PO Number: ${appliedFilters.searchTerm}`}
                    {appliedFilters.searchType === 'invoice_number' && `Invoice Number: ${appliedFilters.searchTerm}`}
                </Tag>
            )}
            {appliedFilters.status && (
                <Tag>Status: {statusDisplayMap[appliedFilters.status]}</Tag>
            )}
            {appliedFilters.type === 'recipient' && appliedFilters.company && (
                <Tag>
                    Recipient Company: {companies.find((comp) => comp.id === appliedFilters.company)?.company_name}
                </Tag>
            )}
            {appliedFilters.type === 'supplier' && appliedFilters.company && (
                <Tag>
                    Supplier Company: {companies.find((comp) => comp.id === appliedFilters.company)?.company_name}
                </Tag>
            )}
            {appliedFilters.branch && (
                <Tag>
                    {appliedFilters.type === 'recipient'
                        ? `Recipient Branch: ${branches.find((branch) => branch.id === appliedFilters.branch)?.branch_name}`
                        : `Supplier Branch: ${branches.find((branch) => branch.id === appliedFilters.branch)?.branch_name}`}
                </Tag>
            )}
        </>
    );

    return (
        <div>
            <Row gutter={16} style={{marginBottom: '16px'}}>
                <Col span={12}>
                    <Input
                        addonBefore={
                            <Select
                                value={filters.searchType}
                                onChange={(value) => setFilters({...filters, searchType: value})}
                                style={{width: 150}} // Fixed width for the dropdown
                            >
                                <Option value="id">PO ID</Option>
                                <Option value="po_number">PO Number</Option>
                                <Option value="invoice_number">Invoice Number</Option>
                            </Select>
                        }
                        placeholder={`Search by ${searchTypeDisplayMap[filters.searchType]}`} // Map to display-friendly label
                        value={filters.searchTerm}
                        onChange={(e) => setFilters({...filters, searchTerm: e.target.value})}
                        style={{width: '100%'}} // Ensure the input spans the remaining width
                    />

                </Col>
                <Col span={6}>
                    <Select
                        placeholder="Filter by Status"
                        value={filters.status || undefined}
                        onChange={(value) => setFilters({...filters, status: value})}
                        allowClear
                        style={{width: '100%'}}
                    >
                        {Object.entries(statusDisplayMap).map(([key, label]) => (
                            <Option key={key} value={key}>
                                {label}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={3}>
                    <Button
                        type="primary"
                        icon={<FilterOutlined/>}
                        onClick={handleApplyFilters}
                        style={{width: '100%'}}
                    >
                        Apply
                    </Button>
                </Col>
                <Col span={3}>
                    <Button
                        icon={<ClearOutlined/>}
                        onClick={handleClearFilters}
                        style={{
                            backgroundColor: '#f5222d',
                            borderColor: '#f5222d',
                            color: '#fff',
                            width: '100%',
                        }}
                    >
                        Clear
                    </Button>
                </Col>
            </Row>

            <Row gutter={16} style={{marginBottom: '16px'}}>
                <Col span={6}>
                    <Select
                        value={filters.type}
                        onChange={(value) => setFilters({...filters, type: value, company: '', branch: ''})}
                        style={{width: '100%'}}
                    >
                        <Option value="recipient">Recipient</Option>
                        <Option value="supplier">Supplier</Option>
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        placeholder={`Select ${filters.type === 'recipient' ? 'Recipient' : 'Supplier'} Company`}
                        value={filters.company || undefined}
                        onChange={(value) => setFilters({...filters, company: value, branch: ''})}
                        allowClear
                        style={{width: '100%'}}
                    >
                        {companies.map((company) => (
                            <Option key={company.id} value={company.id}>
                                {company.company_name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6}>
                    <Select
                        placeholder={`Select ${filters.type === 'recipient' ? 'Recipient' : 'Supplier'} Branch`}
                        value={filters.branch || undefined}
                        onChange={(value) => setFilters({...filters, branch: value})}
                        allowClear
                        style={{width: '100%'}}
                        disabled={!filters.company}
                    >
                        {branches.map((branch) => (
                            <Option key={branch.id} value={branch.id}>
                                {branch.branch_name}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>

            <div style={{marginBottom: '16px'}}>{displayedFilters}</div>

            <PurchaseReceiptTable
                data={receipts}
                loading={loading}
                pagination={pagination}
                onTableChange={(pagination) =>
                    setPagination({current: pagination.current, pageSize: pagination.pageSize})
                }
                onRowClick={onViewDetails}
                size="small"
            />
        </div>
    );
});

export default PurchaseReceiptsList;
