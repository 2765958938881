// src/components/CountriesTable.js
import React from 'react';
import {Button, Table} from 'antd';

const CountriesTable = ({ data, loading, pagination, onTableChange, onEditCountry, userRole }) => {
    const columns = [
        {
            title: 'Country Name',
            dataIndex: 'country_name',
            key: 'country_name',
            sorter: (a, b) => a.country_name.localeCompare(b.country_name),
        },
        {
            title: 'Country Code',
            dataIndex: 'country_code',
            key: 'country_code',
        },
        {
            title: 'ISD Code',
            dataIndex: 'isd_code',
            key: 'isd_code',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button
                    type="link"
                    onClick={() => onEditCountry(record)}
                    disabled={userRole !== 'super_admin'} // Disable if not super_admin
                >
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            onChange={onTableChange}
            bordered
        />
    );
};

export default CountriesTable;
