import React from 'react';
import {Card, Descriptions, Button, Typography, Badge} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {formatRupee} from '../../utils/utils';
import {formatDate} from "../../utils/dateUtils";

const {Text} = Typography;

const ReceiptSummary = ({
                            supplier,
                            recipient,
                            items,
                            totalAmount,
                            totalQuantity,
    taxAmount,
                            invoiceDetails,
                            onEditSupplier,
                            onEditRecipient,
                            onEditInvoice,
                        }) => {
    return (
        <Card style={{borderRadius: '8px', padding: 0, marginBottom: 0}}>
            <Descriptions bordered size="small" column={4}>
                {/* Supplier and Recipient */}
                <Descriptions.Item label="Supplier" span={2}>
                    {supplier && supplier.company && supplier.branch ? (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Text strong style={{fontSize: '14px', color: '#000', display: 'block'}}>
                                    {supplier.company.name}
                                </Text>
                                <Text type="secondary" style={{fontSize: '12px', display: 'block', marginTop: '4px'}}>
                                    {supplier.branch.name}
                                </Text>
                            </div>
                            <Button
                                type="link"
                                icon={<EditOutlined/>}
                                onClick={onEditSupplier}
                                style={{
                                    marginLeft: '8px',
                                    padding: '0',
                                    color: '#1890ff',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Modify
                            </Button>
                        </div>
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text type="secondary">No supplier selected</Text>
                            <Button
                                type="link"
                                icon={<EditOutlined/>}
                                onClick={onEditSupplier}
                                style={{
                                    marginLeft: '8px',
                                    padding: '0',
                                    color: '#1890ff',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Modify
                            </Button>
                        </div>
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Recipient" span={2}>
                    {recipient && recipient.company && recipient.branch ? (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Text strong style={{fontSize: '14px', color: '#000', display: 'block'}}>
                                    {recipient.company.name}
                                </Text>
                                <Text type="secondary" style={{fontSize: '12px', display: 'block', marginTop: '4px'}}>
                                    {recipient.branch.name}
                                </Text>
                            </div>
                            <Button
                                type="link"
                                icon={<EditOutlined/>}
                                onClick={onEditRecipient}
                                style={{
                                    marginLeft: '8px',
                                    padding: '0',
                                    color: '#1890ff',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Modify
                            </Button>
                        </div>
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text type="secondary">No recipient selected</Text>
                            <Button
                                type="link"
                                icon={<EditOutlined/>}
                                onClick={onEditRecipient}
                                style={{
                                    marginLeft: '8px',
                                    padding: '0',
                                    color: '#1890ff',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                Modify
                            </Button>
                        </div>
                    )}
                </Descriptions.Item>


                <Descriptions.Item label="Status" span={2}>
                    {invoiceDetails.status || 'N/A'}
                </Descriptions.Item>
                {/* Invoice Details */}
                <Descriptions.Item label="Invoice Number" span={2}>
                    {invoiceDetails.invoiceNumber || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Total Amount" span={2}>
                    {formatRupee(totalAmount)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Date" span={2}>
                    {formatDate(invoiceDetails.invoiceDate) || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Tax Amount" span={2}>
                    {formatRupee(taxAmount)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Amount" span={2}>
                    {formatRupee(invoiceDetails.amount) || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Total Quantity" span={2}>
                    {totalQuantity}
                </Descriptions.Item>
                <Descriptions.Item label="Due Date" span={2}>
                    {formatDate(invoiceDetails.dueDate) || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Remarks" span={2}>
                    {invoiceDetails.remarks || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default ReceiptSummary;
