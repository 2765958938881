// src/utils/dateUtils.js

/**
 * Function to format date and time as "DD-MMM-YYYY HH:MM AM/PM"
 * @param {string} dateStr - Date string to format
 * @returns {string} - Formatted date time string
 */
export const formatDateTime = (dateStr) => {
    if (!dateStr) return '-';
    const date = new Date(dateStr);
    const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    return date.toLocaleString('en-IN', options);
};

/**
 * Function to format date as "DD-MMM-YYYY"
 * @param {string} dateStr - Date string to format
 * @returns {string} - Formatted date string
 */
export const formatDate = (dateStr) => {
    if (!dateStr) return '-';
    const date = new Date(dateStr);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-IN', options);
};
