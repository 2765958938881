import React, {useState} from 'react';
import {Button, Typography, Table, Card, Tooltip, Empty} from 'antd';
import OrderSummary from './OrderSummary';
import {formatRupee, truncateText} from "../../utils/utils";

const { Title } = Typography;

const OrderReviewPage = ({ orderData, onSubmitOrder, handleEditOrderData, onEditCustomer,
    onEditSalesperson, onEditCompanyBranch }) => {

    const [submitting, setSubmitting] = useState(false);

    const handleOrderSubmit = async () => {
        setSubmitting(true); // Disable the button
        try {
            await onSubmitOrder(); // Call the submit function
        } finally {
            setSubmitting(false); // Re-enable the button after submission
        }
    };

    const columns = [
        {
            title: 'Item Code',
            dataIndex: 'item_code',
        },
        {
            title: 'Item Name',
            dataIndex: 'item_name',
            render: (text) => (
                <Tooltip title={text}>
                    {truncateText(text, 40)}
                </Tooltip>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'right',
        },
        {
            title: 'Selling Rate',
            dataIndex: 'item_price',
            align: 'right',
            render: (price) => formatRupee(price),
        },
        {
            title: 'Total Amount',
            align: 'right',
            render: (_, item) => formatRupee(item.item_price * item.quantity),
        },
    ];

    return (
        <div style={{ padding: '10px' }}>
            <OrderSummary
                customer={orderData.customer}
                company={orderData.company}
                branch={orderData.branch}
                totalQuantity={orderData.totalQuantity}
                orderValue={orderData.orderValue}
                totalMRPValue={orderData.totalMRPValue}
                isApproved={orderData.isApproved}
                salesperson={orderData.salesperson}
                onEditCustomer={onEditCustomer}
                onEditSalesperson={onEditSalesperson}
                onEditCompanyBranch={onEditCompanyBranch}
            />

            <Card style={{ marginTop: '10px' }}>
                <Title level={5}>Item Details</Title>
                <Table
                    columns={columns}
                    dataSource={orderData.selectedProducts}
                    rowKey="id"
                    pagination={false}
                    size="small"
                    locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No products selected. Please add products to the transaction."
                        />
                    ),
                }}
                />
            </Card>

            <div style={{ marginTop: '20px', textAlign: 'right' }}>
                <Button onClick={() => handleEditOrderData()} style={{ marginRight: '10px' }}>
                    Edit Order
                </Button>
                <Button type="primary" onClick={handleOrderSubmit} disabled={submitting}>
                    {submitting ? 'Submitting...' : 'Submit Order'}
                </Button>
            </div>
        </div>
    );
};

export default OrderReviewPage;
