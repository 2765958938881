// orderActions.js
import {
    updateSaleOrderApi,
    createSaleOrderApi,
    fetchSaleOrderDetailsApi,
    confirmSaleOrderApi,
    approveOrRejectSaleOrderApi, executeSaleOrderApi
} from './saleOrdersApi';
import { message } from 'antd';

export const handleSubmitOrder = async (
    orderData,
    editingOrderId,
    handleBackToList,
    setSuccessMessage,
    setErrorMessage
) => {
    const { customer, selectedProducts, salesperson, company, branch, isApproved } = orderData;

    console.log("Submit button clicked.");
    console.log("Editing Order ID:", editingOrderId);
    console.log("Order Data:", orderData);

    // Step 1: Validate mandatory fields
    if (!company.id) {
        setErrorMessage('Company is required');
        return;
    }
    if (!branch.id) {
        setErrorMessage('Branch is required');
        return;
    }
    if (!salesperson) {
        setErrorMessage('Salesperson is required');
        return;
    }
    if (!customer || !customer.name || !customer.mobile) {
        setErrorMessage('Customer name and mobile number are required');
        return;
    }

    // Step 2: Validate items
    if (selectedProducts.length === 0) {
        setErrorMessage('At least one product must be added to the sale order');
        return;
    }

    const invalidItems = selectedProducts.filter(item => {
        if (!item) {
            setErrorMessage(`Item code is missing for one of the products`);
            return true;
        }
        if (item.quantity < 1) {
            setErrorMessage(`Quantity for item ${item.item_code} cannot be less than 1`);
            return true;
        }
        if (item.item_price < 0) {
            setErrorMessage(`Price for item ${item.item_code} cannot be less than 0`);
            return true;
        }
        return false;
    });

    if (invalidItems.length > 0) {
        return;
    }

    // Prepare Sale Order Payload
    const saleOrder = {
        customer_name: customer.name,
        mobile_number: customer.mobile,
        customer: customer.id,
        salesperson: orderData.salesperson.id,
        company: company.id,
        branch: branch.id,
        is_approved: isApproved,
        items: selectedProducts.map(item => ({
            id: item.id || null,
            is_inclusive_tax: true,
            item: item.item,
            mrp: item.mrp,
            tax_group: item.tax_group,
            tax_percentage: item.tax_percentage,
            hsn_code: item.hsn_code,
            mop: item.mop,
            ticket_price: item.ticket_price,
            quantity: item.quantity,
            item_price: item.item_price,
        })),
    };

    // Include `order_date` if creating a new order
    if (!editingOrderId) {
        saleOrder.order_date = new Date().toISOString().split('T')[0];
    }

    console.log("Prepared Sale Order Payload:", saleOrder);

    // Step 3: Submit order
    try {
        if (editingOrderId) {
            console.log("Updating existing sale order:", editingOrderId);
            await updateSaleOrderApi(editingOrderId, saleOrder);
            setSuccessMessage('Sale order updated successfully');
        } else {
            console.log("Creating a new sale order");
            await createSaleOrderApi(saleOrder);
            setSuccessMessage('Sale order created successfully');
        }
        handleBackToList();
    } catch (error) {
        console.error("Error during API call:", error);
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            if (typeof errorData === 'object') {
                Object.entries(errorData).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(msg => setErrorMessage(`${key}: ${msg}`));
                    } else {
                        setErrorMessage(`${key}: ${value}`);
                    }
                });
            } else {
                setErrorMessage(errorData);
            }
        } else {
            setErrorMessage('An unexpected error occurred');
        }
    }
};


// Generic handler for different sale order actions
export const handleOrderAction = async ({
    orderId,
    action,
    setOrderDetails,
    setSuccessMessage,
    setErrorMessage,
    setConfirming,
    refreshOrders,
    remarks = '',
}) => {
    if (!orderId) return;

    setConfirming(true);
    try {
        let response;

        // Determine which API to call based on the action type
        if (action === 'confirm') {
            response = await confirmSaleOrderApi(orderId);
        } else if (action === 'approve' || action === 'reject') {
            response = await approveOrRejectSaleOrderApi(orderId, action, remarks);
        } else if (action === 'generateInvoice') {
            // Add generate invoice API call here when available
            // response = await updateSaleOrderApi(orderId, { status: 'invoiced' });

        } else if (action === 'dispatch') {
            // Add dispatch order API call here when available
            // response = await updateSaleOrderApi(orderId, { status: 'dispatched' });
        } else if (action === 'execute') {
            // Call the execute sale order API
            response = await executeSaleOrderApi(orderId);
        }

        setSuccessMessage(`Sale order ${action}d successfully`);

        // Refresh the order details and list
        const updatedOrder = await fetchSaleOrderDetailsApi(orderId);
        setOrderDetails(updatedOrder);
        if (refreshOrders) refreshOrders();
    } catch (error) {
        console.error(`Error ${action}ing sale order:`, error);
        if (error.response && error.response.data) {
            const { status, data } = error.response;
            const errorMessage = typeof data === 'object'
                ? Object.entries(data).map(([key, value]) => `${key}: ${value}`).join('\n')
                : data.message || 'An error occurred';
            setErrorMessage(`Failed to ${action} sale order: ${errorMessage}`);
        } else {
            setErrorMessage(`An unexpected error occurred while ${action}ing the sale order.`);
        }
    } finally {
        setConfirming(false);
    }
};


