import React from 'react';
import {Card, Descriptions, Button, Typography, Badge} from 'antd';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {calculateDiscountPercentage, formatRupee} from "../../utils/utils";

const {Text} = Typography;

const OrderSummary = ({
                          customer,
                          company,
                          branch,
                          totalQuantity,
                          orderValue,
                          totalMRPValue,
                          isApproved,
                          salesperson,
                          onEditCustomer,
                          onEditSalesperson,
                          onEditCompanyBranch,
                      }) => {
    // Calculate discount amount and percentage
    const discountAmount = totalMRPValue > orderValue ? totalMRPValue - orderValue : 0;
    const discountPercentage = calculateDiscountPercentage(parseFloat(totalMRPValue), parseFloat(orderValue));

    return (
        <Card style={{borderRadius: '8px', padding: 0, marginBottom: 0}}>
            <Descriptions bordered size="small" column={8} layout="vertical">
                {/* First Row: Customer and Salesperson */}
                <Descriptions.Item label="Customer" span={3}>
                    {customer ? (
                        <>
                            <Text strong>{customer.name} ({customer.mobile})</Text>
                            <Button
                                type="link"
                                icon={<EditOutlined/>}
                                onClick={onEditCustomer}
                                style={{marginLeft: '8px', padding: '0', color: '#1890ff'}}
                            >
                                Modify
                            </Button>
                        </>
                    ) : (
                        <>
                            <Text type="secondary">No customer selected</Text>
                            <Button
                                type="link"
                                icon={<PlusOutlined/>}
                                onClick={onEditCustomer}
                                style={{marginLeft: '8px', padding: '0', color: '#1890ff'}}
                            >
                                Add Customer
                            </Button>
                        </>
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Salesperson" span={3}>
                    {salesperson ? (
                        <>
                            <Text strong>{salesperson.full_name}</Text>
                            <Button
                                type="link"
                                icon={<EditOutlined/>}
                                onClick={onEditSalesperson}
                                style={{marginLeft: '8px', padding: '0'}}
                            >
                                Change
                            </Button>
                        </>
                    ) : (
                        <>
                            <Text type="secondary">No salesperson selected</Text>
                            <Button
                                type="link"
                                icon={<PlusOutlined/>}
                                onClick={onEditSalesperson}
                                style={{marginLeft: '8px', padding: '0', color: '#1890ff'}}
                            >
                                Add Salesperson
                            </Button>
                        </>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Branch" span={2}>
                    {branch ? branch.name : 'N/A'} ({company ? company.code : 'N/A'})
                    <Button
                        type="link"
                        icon={<EditOutlined/>}
                        onClick={onEditCompanyBranch}
                        style={{marginLeft: '8px', padding: '0'}}
                    >
                        Change
                    </Button>
                </Descriptions.Item>
                {/* Second Row: Total Quantity, Order Value, Total Discount, Approval Status */}
                <Descriptions.Item label="Order Value" span={2}>
                    <Text strong style={{color: '#1890ff'}}>{formatRupee(orderValue)}</Text>
                </Descriptions.Item>

                <Descriptions.Item label="Total Discount" span={2}>
                    {discountAmount > 0 ? (
                        <>
                            <Text>{formatRupee(discountAmount)}</Text>
                            <Text
                                type={isApproved ? 'success' : 'danger'}
                                style={{marginLeft: '4px', fontWeight: 'bold'}}
                            >
                                ({discountPercentage}% off)
                            </Text>
                        </>
                    ) : (
                        <Text strong>No Discount</Text>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Total Quantity" span={2}>
                    <Text strong>{totalQuantity}</Text>
                </Descriptions.Item>

                <Descriptions.Item label="Approval Status" span={2}>
                    <Badge
                        status={isApproved ? 'success' : 'error'}
                        text={isApproved ? 'Approved' : 'Not Approved'}
                        style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: isApproved ? '#52c41a' : '#f5222d',
                        }}
                    />
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default OrderSummary;
