import React, { useState } from 'react';
import { Button, Space, message, Tooltip } from 'antd';
import {
    FileDoneOutlined,
    EditOutlined,
    InfoCircleOutlined,
    CloseOutlined,
    CarryOutOutlined,
    CheckOutlined,
    FileProtectOutlined,
} from '@ant-design/icons';
import {
    confirmPurchaseReceiptApi,
    cancelPurchaseReceiptApi,
    readyToReceivePurchaseReceiptApi,
    receivePurchaseReceiptApi,
    closePurchaseReceiptApi,
} from './procurementApi';
import ConfirmActionModal from "../common/ConfirmActionModal";

const ReceiptActionsForDetailsDrawer = ({
    receiptDetails,
    receiptId,
    setReceiptDetails,
    refreshReceipts,
    setErrorMessage,
    setSuccessMessage,
    handleEditReceipt,
}) => {
    const [confirming, setConfirming] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        title: '',
        confirmationText: '',
        action: null,
        inputRequired: false,
        placeholder: '',
    });
    const [remarks, setRemarks] = useState('');

    const handleConfirmAction = async () => {
        if (modalConfig.inputRequired && !remarks.trim()) {
            setErrorMessage('Remarks are required for this action.');
            return;
        }

        setIsConfirmModalVisible(false);
        setCurrentAction(modalConfig.action);
        setConfirming(true);

        try {
            switch (modalConfig.action) {
                case 'confirm':
                    await confirmPurchaseReceiptApi(receiptId);
                    setSuccessMessage('Purchase receipt confirmed successfully');
                    break;
                case 'cancel':
                    await cancelPurchaseReceiptApi(receiptId, remarks);
                    setSuccessMessage('Purchase receipt cancelled successfully');
                    break;
                case 'readyToReceive':
                    await readyToReceivePurchaseReceiptApi(receiptId);
                    setSuccessMessage('Purchase receipt marked as ready to receive');
                    break;
                case 'receive':
                    await receivePurchaseReceiptApi(receiptId);
                    setSuccessMessage('Purchase receipt received successfully');
                    break;
                case 'close':
                    await closePurchaseReceiptApi(receiptId, remarks);
                    setSuccessMessage('Purchase receipt closed successfully');
                    break;
                default:
                    throw new Error('Invalid action');
            }
            refreshReceipts();
        } catch (error) {
            console.error(`Error performing action:`, error);
            setErrorMessage(error.message || 'An unexpected error occurred while performing the action.');
        } finally {
            setConfirming(false);
            setCurrentAction(null);
            setRemarks('');
        }
    };

    const actionsConfig = [
        {
            key: 'confirm',
            label: 'Confirm',
            icon: <FileDoneOutlined />,
            condition: receiptDetails.status === 'draft',
            type: 'primary',
            color: '#52c41a', // Green
            modalConfig: {
                title: 'Confirm Purchase Receipt',
                confirmationText: 'Are you sure you want to confirm this purchase receipt?',
            },
        },
        {
            key: 'readyToReceive',
            label: 'Ready to Receive',
            icon: <CarryOutOutlined />,
            condition: receiptDetails.status === 'confirmed',
            type: 'default',
            color: '#1890ff', // Blue
            modalConfig: {
                title: 'Mark Ready to Receive',
                confirmationText: 'Are you sure you want to mark this purchase receipt as ready to receive?',
            },
        },
        {
            key: 'receive',
            label: 'Receive',
            icon: <CheckOutlined />,
            condition: receiptDetails.status === 'ready_to_receive',
            type: 'default',
            color: '#13c2c2', // Cyan
            modalConfig: {
                title: 'Receive Purchase Receipt',
                confirmationText: 'Are you sure you want to mark this purchase receipt as received?',
            },
        },
        {
            key: 'close',
            label: 'Close',
            icon: <FileProtectOutlined />,
            condition: receiptDetails.status === 'received',
            type: 'default',
            color: '#faad14', // Gold
            modalConfig: {
                title: 'Close Purchase Receipt',
                confirmationText: 'Are you sure you want to close this purchase receipt?',
                inputRequired: true,
                placeholder: 'Enter closing remarks (optional)',
            },
        },
        {
            key: 'cancel',
            label: 'Cancel',
            icon: <CloseOutlined />,
            condition:
                receiptDetails.status === 'draft' || receiptDetails.status === 'confirmed',
            type: 'danger',
            color: '#f5222d', // Red
            modalConfig: {
                title: 'Cancel Purchase Receipt',
                confirmationText: 'Are you sure you want to cancel this purchase receipt?',
                inputRequired: true,
                placeholder: 'Enter cancellation remarks',
            },
        },
        {
            key: 'edit',
            label: 'Edit',
            icon: <EditOutlined />,
            condition: receiptDetails.status === 'draft',
            type: 'default',
            color: '#2f54eb', // Indigo
            onClick: () => handleEditReceipt(receiptDetails),
        },
        {
            key: 'invoiceDetails',
            label: 'Invoice Details',
            icon: <InfoCircleOutlined />,
            condition: receiptDetails.invoice_number,
            type: 'default',
            color: '#8c8c8c', // Gray
            onClick: () => message.info(`Invoice Details: ${receiptDetails.invoice_number}`),
        },
    ];

    return (
        <>
            <Space wrap>
                {actionsConfig.map(({ key, label, icon, condition, modalConfig, onClick, type, color }) =>
                    condition && (
                        <Tooltip key={key} title={modalConfig?.confirmationText || ''}>
                            <Button
                                type={type}
                                style={{ backgroundColor: color, borderColor: color, color: '#fff' }}
                                icon={icon}
                                loading={confirming && currentAction === key}
                                onClick={
                                    modalConfig
                                        ? () =>
                                              setModalConfig({
                                                  ...modalConfig,
                                                  action: key,
                                              }) || setIsConfirmModalVisible(true)
                                        : onClick
                                }
                            >
                                {label}
                            </Button>
                        </Tooltip>
                    )
                )}
            </Space>

            {/* Confirmation Modal */}
            <ConfirmActionModal
                visible={isConfirmModalVisible}
                onConfirm={handleConfirmAction}
                onCancel={() => setIsConfirmModalVisible(false)}
                title={modalConfig.title}
                confirmationText={modalConfig.confirmationText}
                inputRequired={modalConfig.inputRequired}
                inputValue={remarks}
                setInputValue={setRemarks}
                placeholder={modalConfig.placeholder}
                loading={confirming}
            />
        </>
    );
};

export default ReceiptActionsForDetailsDrawer;
