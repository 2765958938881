const permissionsMap = {
    super_admin: [
        'sales-dashboard',
        'sales',
        'sales_orders',
        'register_customer',
        'procurement',
        'purchase_orders',
        'product_catalogue',
        'states_list',
        'countries_list',
        'inventory',
        'inventory-stock-list',
        'refresh_local_storage',
    ],
    admin: [
        'sales-dashboard',
        'sales',
        'sales_orders',
        'register_customer',
        'procurement',
        'purchase_orders',
        'product_catalogue',
        'states_list',
        'countries_list',
        'inventory',
        'inventory-stock-list',
        'refresh_local_storage',
    ],
    manager: ['dashboard', 'product_catalogue', 'option5', 'option6'],
    staff: ['dashboard', 'product_catalogue', 'purchase_requests_draft', ],
    guest: ['dashboard'],
};

export const hasAccess = (userRole, permission) => {
    if (!userRole) {
        return true;
    }
    const rolePermissions = permissionsMap[userRole] || [];
    return rolePermissions.includes(permission);
};

export default hasAccess;
