import axios from 'axios';
import config from "../../config";
import {getAuthToken, getRoleBasedFilters} from "../../utils/authUtils";
/**
 * Fetch serial number details from the API.
 * @param {string} serialNumber - The serial number to fetch.
 * @returns {Promise<object>} - The serial number details.
 * @throws Will throw an error if the request fails or the serial number does not exist.
 */
export const fetchSerialNumberDetails = async (serialNumber) => {
    const token = getAuthToken();
    try {
        console.log(`${config.inxOmsApiUrl}`);
        const response = await axios.get(
            `${config.inxOmsApiUrl}/inventory/api/serial-numbers/${serialNumber}/`,
            {
            headers: {Authorization: `Token ${token}`,
            },
        });
        console.log("Reponse:", response);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error(`Serial number ${serialNumber} does not exist.`);
        }
        throw new Error(`Failed to fetch serial number ${serialNumber}.`);
    }
};

// Fetch stocks with pagination and filters
export const fetchStocksApi = async (
    page = 1,
    company = '',
    branch = '',
    itemCode = '',
    brand = ''
) => {
    const token = getAuthToken();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/inventory/api/stocks/`, {
            headers: { Authorization: `Token ${token}` },
            params: {
                page,
                company: company || undefined,
                branch: branch || undefined,
                item_code: itemCode || undefined,
                brand: brand || undefined,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('Error response from API:', error.response.data);
        }
        throw new Error('Failed to load stocks');
    }
};
