// src/api/countriesApi.js
import axios from 'axios';
import config from "../../config";

const formatErrorMessages = (data) => {
    return Object.entries(data)
        .map(([field, messages]) => {
            if (Array.isArray(messages)) {
                return `${field}: ${messages.join(", ")}`;
            } else if (typeof messages === 'object' && messages !== null) {
                return `${field}: ${formatErrorMessages(messages)}`; // Recursive call for nested objects
            } else {
                return `${field}: ${messages}`;
            }
        })
        .join(" | ");
};

// Fetch countries list with pagination and search filters
export const fetchCountriesApi = async (page = 1, search = '') => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/countries_list/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load countries');
    }
};

// New function to create a country
export const createCountryApi = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/core_config/api/countries/add/`, data, {
            headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' },
        });
        console.log(response);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
    const errorMessages = formatErrorMessages(error.response.data);
    console.error("Error message:", errorMessages);
    throw new Error(errorMessages); // Pass the detailed message to the calling function
} else {
    console.error("Unexpected error:", error.message);
    throw new Error("A network error occurred. Please try again later.");
}

    }
};

// New function to update a country
export const updateCountryApi = async (id, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.patch(`${config.inxOmsApiUrl}/core_config/api/countries/${id}/update/`, data, {
            headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' },
        });

        return response.data;
    } catch (error) {
        throw new Error('Failed!'+ error);
    }
};