import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

// Fetch countries with states
export const fetchCountriesWithStates = createAsyncThunk(
    'configuration/fetchCountriesWithStates',
    async (_, { getState }) => {
        const state = getState().configuration;

        // Check localStorage
        const storedCountries = localStorage.getItem('countriesWithStates');
        if (storedCountries) {
            try {
                const parsedCountries = JSON.parse(storedCountries);
                if (Array.isArray(parsedCountries) && parsedCountries.length > 0) {
                    return parsedCountries;
                }
            } catch (error) {
                console.error('Error parsing countries from localStorage', error);
            }
        }

        // Fetch from API if not found in state or localStorage
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/countries-with-states/`, {
            headers: { Authorization: `Token ${token}` },
        });

        // Save to localStorage
        localStorage.setItem('countriesWithStates', JSON.stringify(response.data.results));

        return response.data.results;
    }
);

// Fetch territories
export const fetchTerritories = createAsyncThunk(
    'configuration/fetchTerritories',
    async (_, { getState }) => {
        const state = getState().configuration;

        // Check localStorage
        const storedTerritories = localStorage.getItem('territories');
        if (storedTerritories) {
            try {
                const parsedTerritories = JSON.parse(storedTerritories);
                if (Array.isArray(parsedTerritories) && parsedTerritories.length > 0) {
                    return parsedTerritories;
                }
            } catch (error) {
                console.error('Error parsing territories from localStorage', error);
            }
        }

        // Fetch from API if not found in state or localStorage
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.inxOmsApiUrl}/configurations/api/territories`, {
            headers: { Authorization: `Token ${token}` },
        });

        // Save to localStorage
        localStorage.setItem('territories', JSON.stringify(response.data));

        return response.data;
    }
);

// Fetch customer segments
export const fetchCustomerSegments = createAsyncThunk(
    'configuration/fetchCustomerSegments',
    async (_, { getState }) => {
        const state = getState().configuration;

        // Check localStorage
        const storedCustomerSegments = localStorage.getItem('customerSegments');
        if (storedCustomerSegments) {
            try {
                const parsedCustomerSegments = JSON.parse(storedCustomerSegments);
                if (Array.isArray(parsedCustomerSegments) && parsedCustomerSegments.length > 0) {
                    return parsedCustomerSegments;
                }
            } catch (error) {
                console.error('Error parsing customer segments from localStorage', error);
            }
        }

        // Fetch from API if not found in state or localStorage
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.inxOmsApiUrl}/configurations/api/customer_segments/`, {
            headers: { Authorization: `Token ${token}` },
        });

        // Save to localStorage
        localStorage.setItem('customerSegments', JSON.stringify(response.data));

        return response.data;
    }
);

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: {
        countries: [],
        territories: [],
        customerSegments: [],
        loading: false,
        error: null,
        hasFetchedCountries: !!localStorage.getItem('countriesWithStates'),
        hasFetchedTerritories: !!localStorage.getItem('territories'),
        hasFetchedCustomerSegments: !!localStorage.getItem('customerSegments'),
    },
    reducers: {
        clearConfiguration: (state) => {
            state.countries = [];
            state.territories = [];
            state.customerSegments = [];
            state.hasFetchedCountries = false;
            state.hasFetchedTerritories = false;
            state.hasFetchedCustomerSegments = false;
            localStorage.removeItem('countriesWithStates');
            localStorage.removeItem('territories');
            localStorage.removeItem('customerSegments');
        },
    },
    extraReducers: (builder) => {
        // Handle countries with states
        builder
            .addCase(fetchCountriesWithStates.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCountriesWithStates.fulfilled, (state, action) => {
                state.countries = action.payload;
                state.hasFetchedCountries = true;
                state.loading = false;
            })
            .addCase(fetchCountriesWithStates.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })

            // Handle territories
            .addCase(fetchTerritories.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTerritories.fulfilled, (state, action) => {
                state.territories = action.payload;
                state.hasFetchedTerritories = true;
                state.loading = false;
            })
            .addCase(fetchTerritories.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })

            // Handle customer segments
            .addCase(fetchCustomerSegments.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCustomerSegments.fulfilled, (state, action) => {
                state.customerSegments = action.payload;
                state.hasFetchedCustomerSegments = true;
                state.loading = false;
            })
            .addCase(fetchCustomerSegments.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    },
});

export const { clearConfiguration } = configurationSlice.actions;
export default configurationSlice.reducer;
