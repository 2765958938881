import axios from 'axios';
import config from '../../config';
import { getAuthToken, getRoleBasedFilters } from '../../utils/authUtils';

// Fetch purchase receipts with pagination, search, and filters
export const fetchPurchaseReceiptsApi = async ({
    page = 1,
    searchType = '',
    searchTerm = '',
    type = '',
    company = '',
    branch = '',
    status = ''
}) => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    const params = {
        page,
        status: status || undefined,
        ...(type === 'recipient' && { recipient_company: company || undefined, recipient_branch: branch || undefined }),
        ...(type === 'supplier' && { supplier_company: company || undefined, supplier_branch: branch || undefined }),
        ...(searchType === 'id' && { id: searchTerm || undefined }),
        ...(searchType === 'po_number' && { order_number: searchTerm || undefined }),
        ...(searchType === 'invoice_number' && { invoice_number: searchTerm || undefined }),
        ...roleFilters,
    };

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/procurement/api/purchase_receipts/`, {
            headers: { Authorization: `Token ${token}` },
            params,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching purchase receipts:', error);
        throw new Error('Failed to load purchase receipts');
    }
};



export const fetchPurchaseReceiptDetailsApi = async (receiptId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/`, {
            headers: { Authorization: `Token ${token}` },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load purchase receipt details');
    }
};

export const fetchPurchaseReceiptSerialNumbersApi = async (itemId) => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${config.inxOmsApiUrl}/procurement/api/purchase_receipts/items/${itemId}/serial_numbers/`, {
        headers: { Authorization: `Token ${token}` },
    });
    return response.data;
};

// Create Purchase Receipt API
export const createPurchaseReceiptApi = async (receiptData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/add/`,
            receiptData,
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error creating purchase receipt:', error);
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw new Error('Network error: Failed to create purchase receipt');
        }
    }
};

export const editPurchaseReceiptApi = async (receiptId, receiptData) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.put(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/edit/`,
            receiptData,
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error editing purchase receipt:', error);
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw new Error('Network error: Failed to edit purchase receipt');
        }
    }
};


export const confirmPurchaseReceiptApi = async (receiptId) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.patch(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/confirm/`,
            {},
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error confirming purchase receipt:', error);
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw new Error('Network error: Failed to confirm purchase receipt');
        }
    }
};


export const cancelPurchaseReceiptApi = async (receiptId, remarks) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.patch(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/cancel/`,
            { remarks },
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error cancelling purchase receipt:', error);
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw new Error('Network error: Failed to cancel purchase receipt');
        }
    }
};

export const readyToReceivePurchaseReceiptApi = async (receiptId) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/ready-to-receive/`,
            {},
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error marking purchase receipt as ready to receive:', error);
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw new Error('Network error: Failed to mark purchase receipt as ready to receive');
        }
    }
};

export const receivePurchaseReceiptApi = async (receiptId) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.put(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/receive/`,
            {},
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        console.log("Response: ", response);
        return response.data;
    } catch (error) {
        console.error('Error receiving purchase receipt:', error);
        if (error.response && error.response.data) {
            const apiError = error.response.data.error || error.response.data.detail || "An unexpected error occurred";
            throw new Error(apiError);
        }
        throw new Error('Network error: Failed to receive purchase receipt');
    }
};

export const closePurchaseReceiptApi = async (receiptId, remarks) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('User is not authenticated');
    }

    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/procurement/api/purchase_receipts/${receiptId}/close/`,
            { remarks },
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error closing purchase receipt:', error);
        if (error.response && error.response.data) {
            throw error.response.data;
        } else {
            throw new Error('Network error: Failed to close purchase receipt');
        }
    }
};

