import React, {useState} from 'react';
import {Card, Col, Progress, Row, Segmented, Tabs, Tooltip, Typography} from 'antd';
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {formatRupee} from '../../utils/utils';

const {Text, Title} = Typography;

const RevenueAnalysisCard = ({revenueGrowth}) => {
    const [view, setView] = useState('month')

    const renderGrowthIndicator = (growthPercentage, lastValue, metric = "Revenue") => {
        const formatValue = metric === "Revenue" ? formatRupee(lastValue || 0) : `${lastValue}%`;

        if (growthPercentage === null || lastValue === 0) {
            return (
                <Text type="secondary" style={{fontSize: '0.9rem'}}>
                    No {metric} growth comparison available
                </Text>
            );
        }
        return growthPercentage > 0 ? (
        <Text style={{color: 'green', fontWeight: 'bold', fontSize: '1rem'}}>
            <ArrowUpOutlined /> {Math.abs(growthPercentage)}% growth compared to {formatValue}
        </Text>
    ) : (
        <Text style={{color: 'red', fontWeight: 'bold', fontSize: '1rem'}}>
            <ArrowDownOutlined /> {Math.abs(growthPercentage)}% decline compared to {formatValue}
        </Text>
    );
    };

    const renderTargetAchievement = (actual, target) => {
    if (!target || target === 0) {
        return <Text type="secondary">No target set</Text>;
    }
    const achievement = ((actual / target) * 100).toFixed(2);

    // Determine progress bar and text color based on achievement percentage
    let color = 'green'; // Default for 100% and above
    if (achievement < 60) color = 'red';
    else if (achievement >= 60 && achievement < 90) color = 'orange';
    else if (achievement >= 90 && achievement < 100) color = 'blue';

    return (
        <div style={{ marginTop: 8 }}>
            <Tooltip title={`Target: ${formatRupee(target)}`}>
                <Progress
                    percent={Math.min(achievement, 100)}
                    strokeColor={color}
                    trailColor="#f0f0f0"
                    format={(percent) => (
                        <Text
                            style={{
                                fontWeight: 'bold',
                                color: color === 'yellow' ? '#000' : color,
                            }}
                        >
                            {percent}%
                        </Text>
                    )}
                />
            </Tooltip>
        </div>
    );
};


    const renderContent = () => {
        const isMonthly = view === 'month';

        const actualRevenue = isMonthly
            ? revenueGrowth?.current_month_revenue || 0
            : revenueGrowth?.current_quarter_revenue || 0;

        const targetRevenue = isMonthly
            ? revenueGrowth?.monthly_target || 0
            : revenueGrowth?.quarterly_target || 0;

        const growthPercentage = isMonthly
            ? revenueGrowth?.monthly_growth_percentage
            : revenueGrowth?.quarterly_growth_percentage;

        const lastRevenue = isMonthly
            ? revenueGrowth?.last_month_revenue || 0
            : revenueGrowth?.last_quarter_revenue || 0;

        const grossMargin = isMonthly
            ? revenueGrowth?.current_month_gross_margin || 0
            : revenueGrowth?.current_quarter_gross_margin || 0;

        const lastGrossMargin = isMonthly
            ? revenueGrowth?.last_month_gross_margin || 0
            : revenueGrowth?.last_quarter_gross_margin || 0;

        const grossMarginGrowth = isMonthly
            ? revenueGrowth?.monthly_gross_margin_growth_percentage
            : revenueGrowth?.quarterly_gross_margin_growth_percentage;


        return (
            <>
                <Col span={12}>
                    <Title level={5}>Revenue</Title>
                    <Text style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                        {formatRupee(actualRevenue)}
                    </Text>
                    <div style={{marginTop: 8}}>
                        {renderGrowthIndicator(growthPercentage, lastRevenue, "Revenue")}
                    </div>
                    {renderTargetAchievement(actualRevenue, targetRevenue)}
                </Col>
                <Col span={12}>
                    <Title level={5}>Gross Margin</Title>
                    <Text style={{fontSize: '1.2rem', fontWeight: 'bold'}}>
                        {grossMargin}%
                    </Text>
                    <div style={{marginTop: 8}}>
                        {renderGrowthIndicator(grossMarginGrowth, lastGrossMargin, "Gross Margin")}
                    </div>
                </Col>
            </>
        );
    };

    const items = [
        {key: 'month', label: 'Current Month', children: renderContent()},
        {key: 'quarter', label: 'Current Quarter', children: renderContent()},
    ];

    return (
        <Card
            style={{
                borderRadius: 10,
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                background: '#f5f5f5',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Title level={4} style={{margin: 0, flex: 1}}>
                    Revenue & Gross Margin Analysis
                </Title>
                <Segmented
                    options={[
                        { label: 'Current Month', value: 'month' },
                        { label: 'Current Quarter', value: 'quarter' },
                    ]}
                    value={view}
                    onChange={(value) => setView(value)}
                />
            </div>
            <Row
                gutter={[16, 16]}
                justify="center"
                align="middle"
                style={{marginTop: 12, textAlign: 'center'}}
            >
                {renderContent()}
            </Row>
        </Card>
    );
};

export default RevenueAnalysisCard;
