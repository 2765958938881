// src/utils/authUtils.js

// Get Authentication Token from local storage
export const getAuthToken = () => localStorage.getItem('token');

// Get User Role from local storage
export const getUserRole = () => localStorage.getItem('user_role');

// Get Company ID from local storage
export const getCompanyId = () => localStorage.getItem('company_id');

// Get Branch ID from local storage
export const getBranchId = () => localStorage.getItem('branch_id');

// Utility function to get filters based on user role
export const getRoleBasedFilters = () => {
    const userRole = getUserRole();
    const filters = {};

    if (userRole === 'staff' || userRole === 'store_manager') {
        filters.company = getCompanyId();
        filters.branch = getBranchId();
    } else if (userRole === 'company_manager') {
        filters.company = getCompanyId();
    }

    return filters;
};
