import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

// Optimized fetchCompanies with a conditional API call
// Optimized fetchCompanies with LocalStorage check
export const fetchCompanies = createAsyncThunk(
    'companyBranch/fetchCompanies',
    async (_, { getState, rejectWithValue }) => {
        const state = getState().companyBranch;

        // Check if companies are already in the state
        if (state.companies.length > 0) {
            return state.companies;
        }

        // Check localStorage for cached companies
        const storedCompanies = localStorage.getItem('companies');
        if (storedCompanies) {
            try {
                const parsedCompanies = JSON.parse(storedCompanies);
                if (Array.isArray(parsedCompanies) && parsedCompanies.length > 0) {
                    return parsedCompanies; // Return companies from localStorage
                }
            } catch (error) {
                console.error('Error parsing companies from localStorage:', error);
            }
        }

        // Fetch companies from API if not found in state or localStorage
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/companies/`, {
                headers: { Authorization: `Token ${token}` }
            });

            // Save companies to localStorage
            localStorage.setItem('companies', JSON.stringify(response.data));

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);


export const refreshCompanies = createAsyncThunk(
    'companyBranch/refreshCompanies',
    async (_, { dispatch }) => {
        localStorage.removeItem('companies'); // Clear companies from local storage
        return dispatch(fetchCompanies()); // Re-fetch companies
    }
);



const companyBranchSlice = createSlice({
    name: 'companyBranch',
    initialState: {
        companies: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanies.pending, (state) => {
                state.loading = true;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.companies = action.payload; // Update with fetched companies
                state.loading = false;
            })
            .addCase(fetchCompanies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload; // Set error message
            });
    },
});

export default companyBranchSlice.reducer;
