import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, message, Row, Typography} from 'antd';
import {ArrowLeftOutlined, PlusOutlined} from '@ant-design/icons';
import PurchaseReceiptsList from '../features/procurement/PurchaseReceiptsList'; // Component for listing purchase receipts
import CreatePurchaseReceipt from '../features/procurement/CreatePurchaseReceipt'; // Component for creating purchase receipt
import PurchaseReceiptReview from '../features/procurement/PurchaseReceiptReview'; // Component for reviewing receipt
import ReceiptSummary from "../features/procurement/ReceiptSummary"; // Summary Component
import CompanyBranchSelector from "../features/common/CompanyBranchSelector"; // For Supplier/Recipient Selection
import PurchaseReceiptDetailsDrawer from '../features/procurement/PurchaseReceiptDetailsDrawer';
import {handleSubmitReceipt} from "../features/procurement/procurementActions"; // Receipt Details Drawer

const {Title} = Typography;

const ProcurementPage = () => {
    const receiptsListRef = useRef();

    // State for managing steps and receipt data
    const [step, setStep] = useState('list'); // "list", "create", "review"
    const [selectedReceiptId, setSelectedReceiptId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [supplier, setSupplier] = useState(null);
    const [recipient, setRecipient] = useState(null);

    // Fetch user and company details from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const companyId = user?.company_id || '';
    const companyName = user?.company || '';
    const companyCode = user?.company_code || '';
    const branchId = user?.branch_id || '';
    const branchName = user?.branch || '';
    const branchCode = user?.branch_code || '';
    const [items, setItems] = useState([]);

    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    // State for receipt data
    const [receiptData, setReceiptData] = useState({
        supplier: {
            company: null,
            branch: null
        },
        recipient: {
            company: {id: companyId, name: companyName, code: companyCode},
            branch: {id: branchId, name: branchName, code: branchCode},
        },
        totalAmount: 0,
        totalQuantity: 0,
        isInclusiveTax: false,
        remarks: '',
        invoiceDetails: {
            invoiceNumber: '',
            invoiceDate: null,
            dueDate: null,
            amount: 0,
        },
    });

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    // Use this in the ProcurementPage component
    const handleSubmitOrder = async () => {
        console.log("receiptData", receiptData);
        console.log("items", items);

        try {
            await handleSubmitReceipt(
                receiptData,
                items,
                handleBackToList,
                setSuccessMessage,
                setErrorMessage
            );
        } catch (error) {
            console.error("Failed to submit receipt:", error);
        }
    };

    // Centralized Calculation Function
    const calculateTotals = (items, isInclusiveTax) => {
        let totalAmount = 0;
        let totalTax = 0;
        let totalQuantity = 0;

        const updatedItems = items.map((item) => {
            const taxRate = parseFloat(item.tax_group_name) || 0; // Ensure tax rate is valid
            const baseAmount = item.item_price * item.quantity;
            let taxAmount = 0;

            if (isInclusiveTax) {
                taxAmount = (baseAmount / (100 + taxRate)) * taxRate; // Tax extracted from inclusive price
            } else {
                taxAmount = (baseAmount * taxRate) / 100; // Tax added for exclusive price
            }

            const totalAmountForItem = isInclusiveTax ? baseAmount : baseAmount + taxAmount;

            // Update totals
            totalQuantity += item.quantity;
            totalTax += taxAmount;
            totalAmount += totalAmountForItem;

            // Return updated item with totalAmount
            return {
                ...item,
                totalAmount: totalAmountForItem,
            };
        });

        return {updatedItems, totalAmount, totalTax, totalQuantity};
    };


    // Update Totals Whenever Items or Tax Type Change
    useEffect(() => {
        const {updatedItems, totalAmount, totalTax, totalQuantity} = calculateTotals(items, receiptData.isInclusiveTax);

        // Update items only if they differ
        if (JSON.stringify(items) !== JSON.stringify(updatedItems)) {
            setItems(updatedItems);
        }

        // Update receiptData only if totals have changed
        setReceiptData((prev) => {
            if (
                prev.totalAmount !== totalAmount ||
                prev.taxAmount !== totalTax ||
                prev.totalQuantity !== totalQuantity
            ) {
                return {...prev, totalAmount, taxAmount: totalTax, totalQuantity};
            }
            return prev; // Avoid triggering a re-render.
        });
    }, [items, receiptData.isInclusiveTax]);


    // Handle back to list
    const handleBackToList = () => {
        setStep('list');
        setSelectedReceiptId(null);
    };

    // Initialize new receipt data
    const handleAddNewReceipt = () => {
        setItems([]);
        setReceiptData({
            supplier: {
                company: null,
                branch: null,
            },
            recipient: {
                company: {id: companyId, name: companyName, code: companyCode},
                branch: {id: branchId, name: branchName, code: branchCode},
            },
            totalAmount: 0,
            taxAmount: 0,
            totalQuantity: 0,
            isInclusiveTax: false,
            invoiceDetails: {
                invoiceNumber: '',
                invoiceDate: null,
                dueDate: null,
                amount: 0,
            },
        });
        setStep('create');
    };

    const handleEditReceipt = (receiptData) => {
        // Use calculateTotals to compute totals from items
        const {updatedItems, totalAmount, totalTax, totalQuantity} = calculateTotals(
            receiptData.items.map((item) => ({
                ...item,
                id: item.id,
                item: item.item,
                mrp: parseFloat(item.mrp),
                quantity: item.received_quantity, // Map received_quantity as quantity
                item_price: parseFloat(item.item_price), // Ensure numeric type
                tax_group_name: parseFloat(item.tax_group_percentage), // Map tax percentage
            })),
            receiptData.items[0]?.is_inclusive_tax || false // Use the tax inclusion flag from the first item
        );

        // Update receipt data with calculated totals
        setReceiptData({
            supplier: {
                company: {
                    id: receiptData.supplier_company,
                    name: receiptData.supplier_company_name,
                    code: receiptData.supplier_company_code,
                },
                branch: {
                    id: receiptData.supplier_branch,
                    name: receiptData.supplier_branch_name,
                    code: receiptData.supplier_branch_code,
                },
            },
            recipient: {
                company: {
                    id: receiptData.recipient_company,
                    name: receiptData.recipient_company_name,
                    code: receiptData.recipient_company_code,
                },
                branch: {
                    id: receiptData.recipient_branch,
                    name: receiptData.recipient_branch_name,
                    code: receiptData.recipient_branch_code,
                },
            },
            id: receiptData.id,
            totalAmount: totalAmount || 0,
            totalQuantity: totalQuantity || 0,
            taxAmount: totalTax || 0, // Add tax amount
            isInclusiveTax: receiptData.items[0]?.is_inclusive_tax || false,
            remarks: receiptData.remarks || '',
            invoiceDetails: {
                invoiceNumber: receiptData.invoice_number || '',
                invoiceDate: receiptData.invoice_date || null,
                dueDate: receiptData.invoice_due_date || null,
                amount: receiptData.invoice_amount || 0,
            },
        });

        // Populate the items with updated totals
        setItems(updatedItems || []);
        setIsEditing(true);
        // Switch to the "create" step for editing
        setStep('create');
        setIsDrawerVisible(false);
    };


    // View details of a selected receipt
    const handleViewReceiptDetails = (receiptId) => {
        setSelectedReceiptId(receiptId);
        setIsDrawerVisible(true); // Open the details drawer
        setIsEditing(false); // Ensure not in editing mode
    };

    const handleCloseDrawer = () => {
        setIsDrawerVisible(false); // Close the details drawer
    };

    // Update supplier or recipient in receipt data
    const handleEditSupplier = (updatedSupplier) => {
        setReceiptData((prev) => ({
            ...prev,
            supplier: updatedSupplier,
        }));
        setSupplier(null);
    };

    const handleEditRecipient = (updatedRecipient) => {
        setReceiptData((prev) => ({
            ...prev,
            recipient: updatedRecipient,
        }));
        setRecipient(null);
    };


    // Handle navigation to the review step
    const handleReviewOrder = () => {
        setStep('review');
    };

    const refreshReceipts = async () => {
        try {
            if (receiptsListRef.current && typeof receiptsListRef.current.refreshReceipts === 'function') {
                await receiptsListRef.current.refreshReceipts(); // Call the refresh method on the child component
                // setSuccessMessage("Receipts list refreshed successfully"); // Display a success message
            } else {
                throw new Error("Refresh method not found on the receipts list component.");
            }
        } catch (error) {
            setErrorMessage("Failed to refresh the receipts list");
            console.error("Error refreshing receipts:", error);
        }
    };


    // Responsive header layout
    const renderHeader = () => (
        <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={12}>
                <Title level={4}>
                    {step === 'create'
                        ? 'Create Purchase Receipt'
                        : step === 'review'
                            ? 'Review Purchase Receipt'
                            : 'Purchase Receipts'}
                </Title>
            </Col>
            <Col xs={24} sm={12} style={{textAlign: 'right'}}>
                {step !== 'list' ? (
                    <Button icon={<ArrowLeftOutlined/>} onClick={handleBackToList}>
                        Back to List
                    </Button>
                ) : (
                    <Button type="primary" icon={<PlusOutlined/>} onClick={handleAddNewReceipt}>
                        Add New Receipt
                    </Button>
                )}
            </Col>
        </Row>
    );

    return (
        <div style={{padding: 8}}>
            {contextHolder}

            {renderHeader()}

            {/* List of Receipts */}
            {step === 'list' && (
                <>
                    <PurchaseReceiptsList
                        ref={receiptsListRef}
                        onViewDetails={handleViewReceiptDetails}
                        setErrorMessage={setErrorMessage}
                    />
                    {/*<button onClick={refreshReceipts}>Refresh List</button>*/}
                </>
            )}

            {/* Supplier and Recipient Selection */}
            <CompanyBranchSelector
                visible={!!supplier}
                onClose={() => setSupplier(null)}
                currentCompany={supplier?.company || {}}
                currentBranch={supplier?.branch || {}}
                onSelectCompanyBranch={handleEditSupplier}
            />
            <CompanyBranchSelector
                visible={!!recipient}
                onClose={() => setRecipient(null)}
                currentCompany={recipient?.company || {}}
                currentBranch={recipient?.branch || {}}
                onSelectCompanyBranch={handleEditRecipient}
            />

            {/* Create Receipt Form */}
            {step === 'create' && (
                <>
                    <ReceiptSummary
                        supplier={receiptData.supplier}
                        recipient={receiptData.recipient}
                        items={items}
                        totalAmount={receiptData.totalAmount}
                        totalQuantity={receiptData.totalQuantity}
                        taxAmount={receiptData.taxAmount}
                        invoiceDetails={receiptData.invoiceDetails}
                        onEditSupplier={() => setSupplier(receiptData.supplier)}
                        onEditRecipient={() => setRecipient(receiptData.recipient)}
                        onEditInvoice={() => console.log('Edit Invoice')}
                    />
                    <CreatePurchaseReceipt
                        items={items}
                        setItems={setItems}
                        receiptData={receiptData}
                        setReceiptData={setReceiptData}
                        onReviewOrder={handleReviewOrder}
                        calculateTotals={calculateTotals}
                        onError={setErrorMessage}
                    />
                </>
            )}

            {/* Review Receipt */}
            {step === 'review' && (
                <PurchaseReceiptReview
                    receiptData={receiptData}
                    items={items}
                    onEdit={() => setStep('create')}
                    onSubmit={handleSubmitOrder}
                    onCancel={handleBackToList}
                />
            )}

            {/* Receipt Details Drawer */}
            {selectedReceiptId && (
                <PurchaseReceiptDetailsDrawer
                    visible={isDrawerVisible}
                    receiptId={selectedReceiptId}
                    onClose={handleCloseDrawer}
                    refreshReceipts={refreshReceipts}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                    handleEditReceipt={handleEditReceipt}
                />
            )}
        </div>
    );
};

export default ProcurementPage;
