import React from 'react';
import {Card, Col, Table, Tooltip} from 'antd';
import {LineChartOutlined} from '@ant-design/icons';
import {formatRupee, truncateText} from '../../utils/utils';

const BranchPerformanceTable = ({branchPerformance = []}) => {
    const columns = [
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Company',
            dataIndex: 'company_code',
            key: 'company_code',
            ellipsis: true, // Prevent content wrapping
        },
        {
            title: 'Revenue',
            dataIndex: 'current_month_revenue',
            key: 'current_month_revenue',
            align: 'right',
            render: (value, record) => {
                const growth = record.current_month_revenue - record.last_month_revenue;
                const growthPercentage =
                    record.last_month_revenue > 0
                        ? ((growth / record.last_month_revenue) * 100).toFixed(2)
                        : null;

                const isGrowth = growth > 0;

                return (
                    <Tooltip
                        title={`Last Month: ${formatRupee(record.last_month_revenue || 0)}`}
                        placement="topRight"
                    >
                        <span
                            style={{
                                color: isGrowth ? 'green' : growth < 0 ? 'red' : 'inherit',
                                fontWeight: 'bold',
                            }}
                        >
                            {formatRupee(value)}{' '}
                            {growthPercentage !== null && (
                                <span>
                                    ({isGrowth ? '+' : ''}
                                    {growthPercentage}%)
                                </span>
                            )}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Target Revenue',
            dataIndex: 'current_month_revenue_target',
            key: 'current_month_revenue_target',
            align: 'right',
            render: (value) => formatRupee(value || 0),
        },
        {
            title: 'Target Achieved (%)',
            key: 'target_achieved',
            align: 'right',
            render: (_, record) => {
                const achievedPercentage =
                    record.current_month_revenue_target > 0
                        ? ((record.current_month_revenue / record.current_month_revenue_target) * 100).toFixed(2)
                        : null;

                return achievedPercentage !== null ? `${achievedPercentage}%` : 'N/A';
            },
        },
    ];

    return (
        <Col xs={24} md={24}>
            <Card
                title={
                    <>
                        <LineChartOutlined/> Branch Performance
                    </>
                }
                style={{height: '100%', padding: '0'}}
            >
                <Table
                    dataSource={branchPerformance.map((branch, index) => ({
                        ...branch,
                        key: index,
                    }))}
                    columns={columns}
                    pagination={false}
                    size="small"
                    locale={{emptyText: 'No branch performance data available'}} // Fallback for empty data
                    scroll={{x: '100%'}} // Enable horizontal scroll
                />
            </Card>
        </Col>
    );
};

export default BranchPerformanceTable;
