import React from 'react';
import {Table, Tooltip, Tag, Button, Empty} from 'antd';
import {formatRupee } from '../../utils/utils';
import {formatDate} from "../../utils/dateUtils";

const PurchaseReceiptTable = ({ data, loading, pagination, onTableChange, onViewDetails, onRowClick, size }) => {
    const columns = [
        // {
        //     title: 'PO ID',
        //     dataIndex: 'id',
        //     key: 'id',
        // },
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            key: 'order_number',
            render: (text) => (
                <Tooltip title={text}>
                    <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier_company_name',
            key: 'supplier_company_name',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            render: (value) => (value ? formatDate(value) : '-'),
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Amount',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            render: (value) => formatRupee(value),
        },
        {
            title: 'Due Date',
            dataIndex: 'invoice_due_date',
            key: 'invoice_due_date',
            render: (value) => (value ? formatDate(value) : '-'),
        },
        {
            title: 'Status',
            dataIndex: 'status_display_name',
            key: 'status',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{text || '-'}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Recipient',
            key: 'recipient',
            render: (_, record) => (
                <Tooltip
                    title={`${record.recipient_branch_name || ''} (${record.recipient_company_code || ''})`}
                >
                    {record.recipient_branch_name ? (
                        <>
                            {record.recipient_branch_name}{' '}
                            {record.recipient_company_code && (
                                <span style={{ color: 'gray' }}>({record.recipient_company_code})</span>
                            )}
                        </>
                    ) : (
                        '-'
                    )}
                </Tooltip>
            ),
        },
    ];

    return (
        <Table
            dataSource={data}
            columns={columns}
            rowKey="id"
            pagination={{
                ...pagination,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} orders`,
            }}
            loading={loading}
            onChange={onTableChange}
            onRow={(record) => ({
                onClick: () => onRowClick(record.id),
            })}
            size={size}
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No orders available."
                    />
                ),
            }}
        />
    );
};

export default PurchaseReceiptTable;
