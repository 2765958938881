// src/api/statesApi.js
import axios from 'axios';
import config from "../../config";

// Fetch states list with pagination and search filters
export const fetchStatesApi = async (page = 1, search = '') => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/states_list/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load states');
    }
};
