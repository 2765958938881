import axios from 'axios';
import config from "../../config";
import { getAuthToken } from "../../utils/authUtils";
import { message } from 'antd';

const apiClient = axios.create({
  baseURL: `${config.inxOmsApiUrl}/analytics/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add Authorization token dynamically
const setAuthHeader = () => {
  const token = getAuthToken();
  if (token) {
    apiClient.defaults.headers['Authorization'] = `Token ${token}`;
  }
};

// Error handling function
const handleApiError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    if (status === 401) {
      message.error('Unauthorized access. Please log in again.');
    } else if (status === 403) {
      message.error('Forbidden access.');
    } else if (status === 404) {
      message.error('Data not found.');
    } else {
      message.error(data?.detail || 'An error occurred');
    }
  } else if (error.request) {
    message.error('No response from server. Please check your network.');
  } else {
    message.error(`Error: ${error.message}`);
  }
  throw error;
};

// Fetch API Data
const fetchData = async (endpoint) => {
  setAuthHeader();
  try {
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Exported API functions
export const fetchBestSellingItems = async () => fetchData('/best-selling-items/');
export const fetchBranchPerformance = async () => fetchData('/branch-performance/');
export const fetchOrderStatusCount = async () => fetchData('/order-status/');
export const fetchBranchStatusOpenOrders = async () => fetchData('/branch-status-open-orders/');
export const fetchRevenueGrowthAnalysis = async () => fetchData('/revenue-growth-analysis/');
