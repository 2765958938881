import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginAPI, checkSessionAPI, logoutAPI, someDataAPI } from './authActions';
import {clearProducts} from "../products/productSlice";

// Background data fetch action
export const fetchBackgroundData = createAsyncThunk('auth/fetchBackgroundData', async (_, { getState }) => {
  const { token } = getState().auth;
  const response = await someDataAPI(token);
  return response.data;
});

// Login Action
export const login = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await loginAPI(credentials);
    const {
      token,
      user_id,
      username,
      full_name,
      company,
        company_id,
        company_code,
      branch,
        branch_id,
        branch_code,
      profile_picture,
      is_active,
      user_role,
    } = response.data;

    // Save token and user data in localStorage
    const userData = { user_id, username, full_name, company, company_id, company_code, branch, branch_id,branch_code, profile_picture, is_active, user_role };
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));

    return { token, ...userData };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Check Session Action
export const checkSession = createAsyncThunk('auth/checkSession', async () => {
  const response = await checkSessionAPI();
  if (response.data.authenticated) {
    return response.data.user;
  } else {
    throw new Error("Not authenticated");
  }
});

// Logout Action
export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  await logoutAPI();

  dispatch(clearProducts()); // Clear product data on logout
  // Clear specific items in localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('collapsed');
  localStorage.clear();
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null,
    loading: false,
    logoutLoading: false,
    error: null,
    backgroundData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login Cases
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload; // Contains user data directly
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Use payload to access error message
      })

      // Check Session Case
      .addCase(checkSession.fulfilled, (state, action) => {
        state.user = action.payload;
      })

      // Logout Case
        .addCase(logout.pending, (state) => {
        state.logoutLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.logoutLoading = false;
        state.user = null;
        state.token = null;
        state.collapsed = null;
        state.backgroundData = null;
      })
        .addCase(logout.rejected, (state) => {
        state.logoutLoading = false;
      })

      // Background Data Fetch Cases
      .addCase(fetchBackgroundData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBackgroundData.fulfilled, (state, action) => {
        state.loading = false;
        state.backgroundData = action.payload;
      })
      .addCase(fetchBackgroundData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default authSlice.reducer;
