import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
    LockOutlined,
    MobileOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    LoginForm,
    ProConfigProvider,
    ProFormCheckbox,
    ProFormText,
} from '@ant-design/pro-components';
import {Button, message, Tabs, theme, Input} from 'antd';
import './Login.css';
import {login} from "./authSlice";

// Constants for messages
const LOGIN_SUCCESS = "Login successful!";
const LOGIN_ERROR = "Login failed!";
const OTP_SENT = "OTP sent successfully! Please check your mobile.";

const Login = () => {
    const {token} = theme.useToken();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loginType, setLoginType] = useState('account');
    const [otpSent, setOtpSent] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    const handleLogin = async (values) => {
        setLoading(true);
        try {
            await dispatch(login(values)).unwrap();
            setSuccessMessage(LOGIN_SUCCESS);
            navigate('/dashboard');
        } catch (error) {
            setErrorMessage(LOGIN_ERROR + " " + error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Display success or error messages based on login status
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
    }, [successMessage, errorMessage, messageApi]);

    const handleGenerateOtp = () => {
        message.success(OTP_SENT);
        setOtpSent(true); // Show OTP input after generating OTP
    };

    const tabItems = [
        {
            key: 'account', label: 'Account Login', children: (
                <>
                    <ProFormText
                        name="username"
                        fieldProps={{
                            size: 'large',
                            prefix: <UserOutlined className="prefixIcon"/>,
                        }}
                        placeholder="Username"
                        rules={[{required: true, message: 'Please enter your username!'}]}
                    />
                    <ProFormText.Password
                        name="password"
                        fieldProps={{
                            size: 'large',
                            prefix: <LockOutlined className="prefixIcon"/>,
                        }}
                        placeholder="Password"
                        rules={[{required: true, message: 'Please enter your password!'}]}
                    />
                </>
            )
        },
        {
            key: 'phone', label: 'Mobile Login', children: (
                <>
                    <ProFormText
                        fieldProps={{
                            size: 'large',
                            prefix: <MobileOutlined className="prefixIcon"/>,
                        }}
                        name="mobile"
                        placeholder="Mobile Number"
                        rules={[
                            {required: true, message: 'Please enter your mobile number!'},
                            {pattern: /^[0-9]{10}$/, message: 'Invalid mobile number format!'},
                        ]}
                    />
                    {!otpSent ? (
                        <Button
                            type="primary"
                            block
                            onClick={handleGenerateOtp}
                            style={{marginTop: 2, marginBottom: 18}}
                            aria-label="Generate OTP"
                        >
                            Generate OTP
                        </Button>
                    ) : (
                        <Input
                            placeholder="Enter OTP"
                            maxLength={6}
                            style={{
                                width: '100%',
                                marginBottom: 18,
                                fontSize: '16px',
                                height: '45px',
                                padding: '2px',
                            }}
                            aria-label="OTP Input"
                        />
                    )}
                </>
            )
        }
    ];

    return (
        <ProConfigProvider hashed={false}>
            {contextHolder}
            <div className="login-background" style={{backgroundColor: token.colorBgContainer}}>
                {/* Logo, Title, and Subtitle */}
                <div className="logo-title-wrapper">
                    <img src={require('../../logo.png')} alt="Logo" className="logo"/>
                    <h1 className="app-title">INX OMS CORE</h1>
                    <p className="app-subtitle">Please sign in to continue</p>
                </div>

                <LoginForm
                    onFinish={handleLogin}
                    submitter={{
                        searchConfig: { submitText: 'Login' },
                    }}
                    className="login-form-wrapper"
                >
                    <Tabs
                        centered
                        activeKey={loginType}
                        onChange={(activeKey) => setLoginType(activeKey)}
                        items={tabItems}
                    />
                    <div style={{marginTop: 2, marginBlockEnd: 24}}>
                        <ProFormCheckbox noStyle name="autoLogin">
                            Remember Me
                        </ProFormCheckbox>
                        <a style={{float: 'right'}}>Forgot Password?</a>
                    </div>
                </LoginForm>
            </div>
        </ProConfigProvider>
    );
};

export default Login;
