import React, {useEffect, useState} from 'react';
import {
    Drawer, Descriptions, Table, Spin, Typography, Tag, Badge, Tooltip, Empty, Button, Space
} from 'antd';
import {fetchSaleOrderDetailsApi, fetchSerialNumbersApi} from './saleOrdersApi';
import {formatRupee, truncateText} from '../../utils/utils';
import {formatDate, formatDateTime} from "../../utils/dateUtils";
import OrderActionsForDetailsDrawer from "./OrderActionsForDetailsDrawer";
import {QrcodeOutlined} from "@ant-design/icons";
import SerialNumberInput from "../common/SerialNumberInput";

const {Text, Title} = Typography;

const SaleOrderDetailsDrawer = ({
                                    visible,
                                    orderId,
                                    onClose,
                                    onEditOrder,
                                    setErrorMessage,
                                    setSuccessMessage,
                                    refreshOrders
                                }) => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [serialNumbers, setSerialNumbers] = useState({});
    const [serialLoading, setSerialLoading] = useState({});
    const [serialFetched, setSerialFetched] = useState({});
    const [showSerialInput, setShowSerialInput] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);


    // State variables for the conditions
    const [isAutomaticallyApproved, setIsAutomaticallyApproved] = useState(false);
    const [isManuallyApproved, setIsManuallyApproved] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [needsApproval, setNeedsApproval] = useState(false);

    useEffect(() => {
        const fetchDetails = async () => {
            if (!orderId) return;
            setLoading(true);
            try {
                const data = await fetchSaleOrderDetailsApi(orderId);
                setOrderDetails(data);
                // Define conditions based on the fetched data
                if (data) {
                    const autoApproved = data.is_approved;
                    const manuallyApproved = data.approved_by && data.status !== "rejected";
                    const rejected = data.status === "rejected";
                    const approvalNeeded = data.status === "draft" || data.status === "awaiting_approval";

                    // Update state variables
                    setIsAutomaticallyApproved(autoApproved);
                    setIsManuallyApproved(manuallyApproved);
                    setIsRejected(rejected);
                    setNeedsApproval(approvalNeeded);
                }
            } catch (error) {
                setErrorMessage('Failed to load sale order details');
            } finally {
                setLoading(false);
            }
        };

        if (visible) fetchDetails();
    }, [visible, orderId]);

    const fetchSerialNumbers = async (itemId) => {
        if (serialFetched[itemId] || serialLoading[itemId]) return;

        setSerialLoading((prev) => ({...prev, [itemId]: true}));
        try {
            const data = await fetchSerialNumbersApi(itemId);
            const serialNumberValues = data.map((serial) => serial.serial_number_value); // Map only serial_number_value

            setSerialNumbers((prev) => ({ ...prev, [itemId]: data }));
            setSerialFetched((prev) => ({ ...prev, [itemId]: true }));
            setSelectedSerialNumbers((prev) => ({ ...prev, [itemId]: serialNumberValues }));
            console.log("selectedSerialNumbers", selectedSerialNumbers);
        } catch (error) {
            setErrorMessage(`Failed to load serial numbers for item ${itemId}`);
        } finally {
            setSerialLoading((prev) => ({...prev, [itemId]: false}));
        }
    };

    const handleSerialIconClick = async (record) => {
        if (!serialFetched[record.id]) {
            await fetchSerialNumbers(record.id); // Ensure serial numbers are fetched
        }
        setSelectedItem(record);
        setShowSerialInput(true);

    };

    const handleEditOrder = (orderData) => {
        onEditOrder(orderData);
        onClose(); // Close the drawer after initiating edit
    };


    const columns = [
        {
            title: 'Item Details',
            key: 'item_details',
            render: (_, record) => (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '4px 0',
                    whiteSpace: 'nowrap'
                }}>
                    {/* Item Code and Brand */}
                    <div style={{flex: 1}}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', lineHeight: '1.2'}}>
                            <Tooltip title={record.item_code || 'N/A'}>
                                {truncateText(record.item_code || 'N/A', 35)} ({record.brand_name || 'N/A'})
                            </Tooltip>
                        </div>
                        {/* Item Name */}
                        <div style={{color: 'gray', fontSize: '12px', lineHeight: '1.2', marginTop: '4px'}}>
                            <Tooltip title={record.item_name || 'N/A'}>
                                {truncateText(record.item_name || 'N/A', 35)}
                            </Tooltip>
                        </div>
                    </div>

                    {/* Serialized Item Icon */}
                    {record.has_serial_no && (
                        <Tooltip title="This is a serialized item">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f0f5ff', // Light complementary background
                                    border: '1px solid #adc6ff', // Subtle border
                                    width: '24px',
                                    height: '24px',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Soft shadow
                                }}
                                onClick={() => handleSerialIconClick(record)}
                            >
                                <QrcodeOutlined
                                    style={{fontSize: '16px', color: '#597ef7'}} // Complementary icon color
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
        }
        , {title: 'Quantity', dataIndex: 'quantity', align: 'right'},
        {
            title: 'MRP',
            dataIndex: 'mrp',
            align: 'right',
            render: (value) => formatRupee(value),
        }, {
            title: 'Selling Rate',
            dataIndex: 'item_price',
            align: 'right',
            render: (value) => formatRupee(value),
        }, {
            title: 'Total Amount',
            align: 'right',
            render: (_, item) => formatRupee(item.item_price * item.quantity),
        },];

    const expandedRowRender = (record) => {
        const serialData = serialNumbers[record.id] || [];
        const isLoading = serialLoading[record.id];
        return (<div style={{padding: '8px'}}>
            {isLoading ? (<Spin/>) : serialData.length > 0 ? (serialData.map((item) => (
                <Tag key={item.serial_number_value} style={{marginBottom: '8px'}}>
                    {item.serial_number_value}
                </Tag>))) : (<Text type="secondary">No serial numbers assigned</Text>)}
        </div>);
    };


    return (<Drawer
        title={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Title level={4} style={{margin: 0}}>Sales Order Details</Title>
            {orderDetails && (<OrderActionsForDetailsDrawer
                orderDetails={orderDetails}
                orderId={orderId}
                setOrderDetails={setOrderDetails}
                handleEditOrder={onEditOrder}
                refreshOrders={refreshOrders}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
            />)}
        </div>}
        width={window.innerWidth > 1200 ? '70%' : '85%'}
        open={visible}
        onClose={onClose}
    >
        {loading ? (<Spin/>) : orderDetails ? (<>
            {/* Order Summary */}
            <Descriptions bordered size="small" column={4} style={{marginBottom: '20px'}}>
                {/* Order Number, Customer, and Salesperson */}
                <Descriptions.Item label="Order Number" span={2}>
                    {orderDetails.order_number}
                </Descriptions.Item>
                <Descriptions.Item label="Customer" span={2}>
                    {orderDetails.customer ? (// Display customer name and mobile if registered
                        <>
                            {orderDetails.customer_name} ({orderDetails.mobile_number})
                        </>) : (// Tooltip covering the entire section when customer is not registered
                        <Tooltip title="Customer is not registered in the system">
            <span>
                <Badge status="warning" style={{marginRight: 8}}/>
                {orderDetails.customer_name} ({orderDetails.mobile_number})
            </span>
                        </Tooltip>)}
                </Descriptions.Item>


                <Descriptions.Item label="Order Date" span={2}>
                    {formatDate(orderDetails.order_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Salesperson" span={2}>
                    {orderDetails.salesperson_name ? (
                        <>
                            {orderDetails.salesperson_name}
                            {orderDetails.salesperson_company_code && (
                                <span style={{color: 'gray'}}> ({orderDetails.salesperson_company_code})</span>
                            )}
                        </>
                    ) : (
                        'Not Assigned'
                    )}
                </Descriptions.Item>

                {/* Status and Approval Details */}
                <Descriptions.Item label="Status" span={2}>
                    <Badge
                        status={
                            isAutomaticallyApproved || isManuallyApproved
                                ? 'success'
                                : isRejected
                                    ? 'error'
                                    : 'warning'
                        }
                        style={{marginRight: 8}}
                    />
                    {orderDetails.status_display}
                </Descriptions.Item>

                <Descriptions.Item label="Approval Status" span={2}>
                    <Tooltip
                        title={
                            isAutomaticallyApproved
                                ? "This order was approved automatically"
                                : isRejected
                                    ? "The order was rejected by the approver"
                                    : needsApproval
                                        ? "This order needs approval"
                                        : "The order was approved manually by the approver"
                        }
                    >
                        <span>
                            <Badge
                                status={
                                    isAutomaticallyApproved || isManuallyApproved
                                        ? 'success'
                                        : isRejected
                                            ? 'error'
                                            : 'warning'
                                }
                                style={{marginRight: 8}}
                            />
                            {isAutomaticallyApproved
                                ? 'Automatically Approved'
                                : isRejected
                                    ? 'Approval Refused by Approver'
                                    : needsApproval
                                        ? 'Needs Approval'
                                        : 'Approval Authorized by Approver'}
                        </span>
                    </Tooltip>
                </Descriptions.Item>

                <Descriptions.Item label="Total Quantity" span={1}>
                    {orderDetails.total_quantity}
                </Descriptions.Item>
                <Descriptions.Item label="Order Value" span={1}>
                    {formatRupee(orderDetails.order_value)}
                </Descriptions.Item>
                <Descriptions.Item label="Total Discount" span={2}>
                    {orderDetails.total_discount_amount > 0 ? (<>
                        {formatRupee(orderDetails.total_discount_amount)} ({orderDetails.total_discount_percentage}%)
                    </>) : (<Text>No Discount</Text>)}
                </Descriptions.Item>

            </Descriptions>

            <Title level={5} style={{marginTop: 20}}>Items</Title>
            <Table
                columns={columns}
                dataSource={orderDetails.items}
                rowKey="id"
                pagination={false}
                size="small"
                expandable={{
                    expandedRowRender, onExpand: async (expanded, record) => {
                        if (expanded && !serialFetched[record.id]) {
                            await fetchSerialNumbers(record.id);
                        }
                    },
                }}
                locale={{emptyText: <Empty description="No products available"/>}}
                scroll={{x: 800}}
            />

            <Descriptions bordered size="small" column={4} style={{marginTop: 20}}>
                <Descriptions.Item label="Additional Discount" span={4}>
                    {formatRupee(orderDetails.additional_discount_amount || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Date" span={2}>
                    {formatDate(orderDetails.invoice_date || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Number" span={2}>
                    {formatDate(orderDetails.invoice_number || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Amount" span={2}>
                    {formatRupee(orderDetails.invoice_amount || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Balance" span={2}>
                    {formatRupee(orderDetails.invoice_amount || 0)}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions bordered size="small" column={window.innerWidth > 800 ? 2 : 1}
                          style={{marginTop: 20}}>
                <Descriptions.Item label="Company">
                    {orderDetails.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="Branch">
                    {orderDetails.branch_name}
                </Descriptions.Item>
                <Descriptions.Item label="Created Date">
                    {formatDateTime(orderDetails.created_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Created By">
                    {orderDetails.created_by_name}
                </Descriptions.Item>
                <Descriptions.Item label="Updated Date">
                    {formatDateTime(orderDetails.updated_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Updated By">
                    {orderDetails.updated_by_name}
                </Descriptions.Item>
                <Descriptions.Item label="Approved Date">
                    {orderDetails.approved_date ? formatDateTime(orderDetails.approved_date) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Approved By">
                    {orderDetails.approved_by_name || '-'}
                </Descriptions.Item>
            </Descriptions>
            {showSerialInput && selectedItem && (
                <div style={{marginTop: 20}}>
                    <SerialNumberInput
                        visible={showSerialInput}
                        onClose={() => setShowSerialInput(false)}
                        transactionType="Sales"
                        orderNumber={orderDetails.order_number}
                        transactionItemId={selectedItem.id}
                        itemCode ={selectedItem.item_code}
                        itemId={selectedItem.item}
                        quantity={selectedItem.quantity}
                        quantityRequired={selectedItem.quantity}
                        branchId={orderDetails.branch_id}
                        companyId={orderDetails.company_id}
                        existingSerialNumbers={selectedSerialNumbers[selectedItem.id] || []} // Pass only relevant serial numbers
                        onSerialNumberChange={(serials) => {
                            console.log('Updated Serial Numbers:', serials);
                        }}
                    />
                </div>
            )}
        </>) : (<Text>No data available</Text>)}
    </Drawer>);
};

export default SaleOrderDetailsDrawer;
