import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Select, Slider, Button, Spin, Empty } from 'antd';
import { fetchItemsApi } from './productSlice';
import ProductCardList from './ProductCardList';

const { Option } = Select;

const ProductCatalog = () => {
  const dispatch = useDispatch();
  const { allItems = [], loading, hasFetched } = useSelector((state) => state.products);

  const [brandFilter, setBrandFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState('');
  const [priceRange, setPriceRange] = useState([0, 2000000]);

  useEffect(() => {
    if (!hasFetched) {
      dispatch(fetchItemsApi());
    }
  }, [dispatch, hasFetched]);

  // Filtered Products
  const filteredProducts = allItems.filter((product) => {
    const matchesBrand = brandFilter ? product.brand_name === brandFilter : true;
    const matchesGroup = groupFilter ? product.item_group_name === groupFilter : true;
    const productPrice = product.mrp ? parseFloat(product.mrp) : 0;
    const matchesPrice =
      productPrice >= priceRange[0] && productPrice <= priceRange[1];
    return matchesBrand && matchesGroup && matchesPrice;
  });

  // Handle Reset Filters
  const resetFilters = () => {
    setBrandFilter('');
    setGroupFilter('');
    setPriceRange([0, 2000000]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Filters Section */}
      <div
        style={{
          padding: '8px',
          borderBottom: '1px solid #f0f0f0',
          backgroundColor: '#fff',
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Select
              placeholder="Filter by Brand"
              style={{ width: '100%' }}
              onChange={(value) => setBrandFilter(value)}
              value={brandFilter || undefined}
              allowClear
            >
              {[...new Set(allItems.map((product) => product.brand_name))].map((brand) => (
                <Option key={brand} value={brand}>
                  {brand}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              placeholder="Filter by Item Group"
              style={{ width: '100%' }}
              onChange={(value) => setGroupFilter(value)}
              value={groupFilter || undefined}
              allowClear
            >
              {[...new Set(allItems.map((product) => product.item_group_name))].map((group) => (
                <Option key={group} value={group}>
                  {group}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Slider
              range
              min={0}
              max={2000000}
              value={priceRange}
              step={1000}
              onChange={(value) => setPriceRange(value)}
            />
            <div style={{ marginTop: '8px', textAlign: 'center' }}>
              Price Range: ₹{priceRange[0]} - ₹{priceRange[1]}
            </div>
          </Col>
          <Col span={6}>
            <Button type="primary" block onClick={resetFilters}>
              Reset Filters
            </Button>
          </Col>
        </Row>
      </div>

      {/* Product List */}
<div style={{ flex: 1, overflowY: 'auto' }}>
  {loading ? (
    <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
  ) : filteredProducts.length > 0 ? (
    <ProductCardList
      products={filteredProducts}
      onAddToCart={(product) => console.log('Add to Cart:', product)}
      onViewDetails={(product) => console.log('View Details:', product)}
    />
  ) : (
    <Empty description="No Products Found" style={{ marginTop: '20px' }} />
  )}
</div>

    </div>
  );
};

export default ProductCatalog;
