import React, {useState, useEffect} from 'react';
import {Button, Drawer, Table, InputNumber, message, Empty} from 'antd';
import ProductDrawer from './ProductDrawer';

const TransactionForm = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);

    const handleAddProduct = (product) => {
        if (selectedProducts.some(item => item.id === product.id)) {
            setInfoMessage('Product already added');
            return;
        }
        setSelectedProducts([...selectedProducts, {...product, quantity: 1}]);
        setSuccessMessage(`${product.item_code} added successfully`);
    };

    const handleQuantityChange = (value, productId) => {
        setSelectedProducts((prev) =>
            prev.map((item) =>
                item.id === productId ? {...item, quantity: value} : item
            )
        );
        setInfoMessage('Quantity updated');
    };

    const handleRemoveProduct = (productId) => {
        const removedProduct = selectedProducts.find(item => item.id === productId);
        setSelectedProducts((prev) => prev.filter(item => item.id !== productId));
        setSuccessMessage(`${removedProduct.item_name} removed successfully`);
    };

    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'information',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, infoMessage, messageApi]);

    const columns = [
        {title: 'Item Code', dataIndex: 'item_code', key: 'item_code'},
        {title: 'Item Name', dataIndex: 'item_name', key: 'item_name'},
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={record.quantity}
                    onChange={(value) => handleQuantityChange(value, record.id)}
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="link" onClick={() => handleRemoveProduct(record.id)}>
                    Remove
                </Button>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={() => setDrawerVisible(true)} style={{marginBottom: 16}}>
                Select Products
            </Button>

            <Table
                dataSource={selectedProducts}
                columns={columns}
                rowKey="id"
                pagination={false}
                bordered
                style={{marginTop: 16}}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No products selected. Please add products to the transaction."
                        />
                    ),
                }}
            />

            <ProductDrawer
                visible={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                onSelectProduct={handleAddProduct}
                selectedProducts={selectedProducts}
            />
        </>
    );
};

export default TransactionForm;
