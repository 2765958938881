import React, { useEffect, useState } from 'react';
import { Modal, Select, Typography, Button, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {fetchCompanies} from "../../store/slices/companyBranchSlice";

const { Text } = Typography;
const { Option } = Select;

const CompanyBranchSelector = ({ visible, onClose, currentCompany, currentBranch, onSelectCompanyBranch }) => {
    const dispatch = useDispatch();
    const { companies, loading } = useSelector((state) => state.companyBranch);

    const [selectedCompany, setSelectedCompany] = useState(currentCompany.id);
    const [selectedBranch, setSelectedBranch] = useState(currentBranch.id);
    const [branches, setBranches] = useState([]);

    // Fetch companies on component mount
    useEffect(() => {
        if (visible) dispatch(fetchCompanies());
    }, [dispatch, visible]);

    // Update branches when the company is changed
    useEffect(() => {
        const company = companies.find((comp) => comp.id === selectedCompany);
        setBranches(company?.branches || []);
    }, [selectedCompany, companies]);

    const handleCompanyChange = (companyId) => {
        setSelectedCompany(companyId);
        setSelectedBranch(null); // Reset branch selection
    };

    const handleSave = () => {
        const selectedCompanyObj = companies.find((comp) => comp.id === selectedCompany);
        const selectedBranchObj = branches.find((branch) => branch.id === selectedBranch);

        onSelectCompanyBranch({
            company: { id: selectedCompanyObj.id, name: selectedCompanyObj.company_name, code: selectedCompanyObj.company_code },
            branch: { id: selectedBranchObj.id, name: selectedBranchObj.branch_name, code: selectedBranchObj.branch_code }
        });
        console.log("CLosing Company Branch Select");
        onClose();
    };

    if (loading) return <Spin />;

    return (
        <Modal
            title="Select Company and Branch"
            open={visible}
            onCancel={onClose}
            onOk={handleSave}
            okText="Save"
            cancelText="Cancel"
            style={{ top: 40 }}
            centered={false}
        >
            <div style={{ marginBottom: '16px' }}>
                <Text strong>Select Company</Text>
                <Select
                    style={{ width: '100%', marginTop: '8px' }}
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    placeholder="Select a company"
                    notFoundContent="No Company Available"
                >
                    {companies.map(company => (
                        <Option key={company.id} value={company.id}>
                            {company.company_name}
                        </Option>
                    ))}
                </Select>
            </div>

            <div>
                <Text strong>Select Branch</Text>
                <Select
                    style={{ width: '100%', marginTop: '8px' }}
                    value={selectedBranch}
                    onChange={(value) => setSelectedBranch(value)}
                    placeholder="Select a branch"
                    disabled={!selectedCompany}
                >
                    {branches.map(branch => (
                        <Option key={branch.id} value={branch.id}>
                            {branch.branch_name}
                        </Option>
                    ))}
                </Select>
            </div>
        </Modal>
    );
};

export default CompanyBranchSelector;
