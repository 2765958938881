import {
    cancelPurchaseReceiptApi, closePurchaseReceiptApi,
    confirmPurchaseReceiptApi,
    createPurchaseReceiptApi, editPurchaseReceiptApi,
    readyToReceivePurchaseReceiptApi, receivePurchaseReceiptApi
} from "./procurementApi";

export const handleSubmitReceipt = async (
    receiptData,
    items,
    handleBackToList,
    setSuccessMessage,
    setErrorMessage
) => {
    try {
        const { supplier, recipient, totalQuantity } = receiptData;

        // Step 1: Validate mandatory fields
        if (!supplier.company?.id || !supplier.branch?.id) {
            throw new Error('Supplier details are incomplete');
        }
        if (!recipient.company?.id || !recipient.branch?.id) {
            throw new Error('Recipient details are incomplete');
        }
        if (totalQuantity < 1) {
            throw new Error('Total quantity must be at least 1');
        }
        if (!items || items.length === 0) {
            throw new Error("At least one item must be included in the receipt");
        }
        console.log("receiptData", receiptData);
        // Prepare payload for the backend
        const payload = {
            ...receiptData,
            supplier_company: supplier.company.id,
            supplier_branch: supplier.branch.id,
            recipient_company: recipient.company.id,
            recipient_branch: recipient.branch.id,
            items: items.map(item => ({
                id: item.id || null,
                item: item.item,
                mrp:item.mrp,
                received_quantity: item.quantity,
                item_price: item.item_price,
                tax_group: item.tax_group || null,
                is_inclusive_tax: receiptData.isInclusiveTax,
                remarks: receiptData.remarks || "",
            })),
        };

        // Step 2: Submit receipt
        if (receiptData.id) {
            // Edit existing purchase receipt
            await editPurchaseReceiptApi(receiptData.id, payload);
            setSuccessMessage('Purchase receipt updated successfully');
        } else {
            // Create a new purchase receipt
            const response = await createPurchaseReceiptApi(payload);
            console.log('Receipt created successfully:', response);
            setSuccessMessage('Purchase receipt created successfully');
        }
        handleBackToList();
    } catch (error) {
        console.error('Error submitting receipt:', error);

        // Handle API or validation error messages
        if (error.response && error.response.data) {
            const errorMessages = Object.entries(error.response.data)
                .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
                .join('\n');
            setErrorMessage(errorMessages);
        } else {
            setErrorMessage(error.message || 'An unexpected error occurred');
        }
    }
};

const handleConfirmPurchaseReceipt = async ({
    receiptId,
    setReceiptDetails,
    setSuccessMessage,
    setErrorMessage,
    setConfirming,
    refreshReceipts,
}) => {
    try {
        setConfirming(true);
        // Call API to confirm the purchase receipt
        const response = await confirmPurchaseReceiptApi(receiptId); // Replace with actual API call
        setSuccessMessage(response.message || 'Purchase receipt confirmed successfully');
        refreshReceipts();
        setReceiptDetails((prev) => ({
            ...prev,
            status: 'confirmed',
        }));
    } catch (error) {
        console.error('Failed to confirm purchase receipt:', error);
        setErrorMessage(error.message || 'Failed to confirm purchase receipt');
    } finally {
        setConfirming(false);
    }
};

const handleEditPurchaseReceipt = async (receiptDetails, onEditReceipt) => {
    try {
        // Navigate to edit mode
        onEditReceipt(receiptDetails);
    } catch (error) {
        console.error('Failed to navigate to edit purchase receipt:', error);
    }
};

const handleCancelPurchaseReceipt = async ({
    receiptId,
    remarks,
    setReceiptDetails,
    setSuccessMessage,
    setErrorMessage,
    setConfirming,
    refreshReceipts,
}) => {
    try {
        setConfirming(true);
        // Call API to cancel the purchase receipt
        const response = await cancelPurchaseReceiptApi(receiptId, remarks); // Replace with actual API call
        setSuccessMessage(response.message || 'Purchase receipt cancelled successfully');
        refreshReceipts();
        setReceiptDetails((prev) => ({
            ...prev,
            status: 'cancelled',
        }));
    } catch (error) {
        console.error('Failed to cancel purchase receipt:', error);
        setErrorMessage(error.message || 'Failed to cancel purchase receipt');
    } finally {
        setConfirming(false);
    }
};

const handleReadyToReceivePurchaseReceipt = async ({
    receiptId,
    setReceiptDetails,
    setSuccessMessage,
    setErrorMessage,
    setConfirming,
    refreshReceipts,
}) => {
    try {
        setConfirming(true);
        // Call API to mark the receipt as ready to receive
        const response = await readyToReceivePurchaseReceiptApi(receiptId); // Replace with actual API call
        setSuccessMessage(response.message || 'Purchase receipt marked as ready to receive');
        refreshReceipts();
        setReceiptDetails((prev) => ({
            ...prev,
            status: 'ready_to_receive',
        }));
    } catch (error) {
        console.error('Failed to mark receipt as ready to receive:', error);
        setErrorMessage(error.message || 'Failed to mark receipt as ready to receive');
    } finally {
        setConfirming(false);
    }
};

const handleReceivePurchaseReceipt = async ({
    receiptId,
    setReceiptDetails,
    setSuccessMessage,
    setErrorMessage,
    setConfirming,
    refreshReceipts,
}) => {
    try {
        setConfirming(true);
        // Call API to mark the receipt as received
        const response = await receivePurchaseReceiptApi(receiptId); // Replace with actual API call
        setSuccessMessage(response.message || 'Purchase receipt received successfully');
        refreshReceipts();
        setReceiptDetails((prev) => ({
            ...prev,
            status: 'received',
        }));
    } catch (error) {
        console.error('Failed to receive purchase receipt:', error);
        setErrorMessage(error.message || 'Failed to receive purchase receipt');
    } finally {
        setConfirming(false);
    }
};

const handleClosePurchaseReceipt = async ({
    receiptId,
    remarks,
    setReceiptDetails,
    setSuccessMessage,
    setErrorMessage,
    setConfirming,
    refreshReceipts,
}) => {
    try {
        setConfirming(true);
        // Call API to close the purchase receipt
        const response = await closePurchaseReceiptApi(receiptId, remarks); // Replace with actual API call
        setSuccessMessage(response.message || 'Purchase receipt closed successfully');
        refreshReceipts();
        setReceiptDetails((prev) => ({
            ...prev,
            status: 'closed',
        }));
    } catch (error) {
        console.error('Failed to close purchase receipt:', error);
        setErrorMessage(error.message || 'Failed to close purchase receipt');
    } finally {
        setConfirming(false);
    }
};
