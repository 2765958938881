import React, {useState} from 'react';
import {Button, Space, message} from 'antd';
import {
    FileDoneOutlined,
    CheckOutlined,
    CloseOutlined,
    CarryOutOutlined,
    DollarOutlined,
    FileProtectOutlined,
    EditOutlined,
} from '@ant-design/icons';
import {handleOrderAction} from './saleOrderActions';
import ConfirmActionModal from "../common/ConfirmActionModal";

const OrderActionsForDetailsDrawer = ({
                                          orderDetails,
                                          orderId,
                                          setOrderDetails,
                                          handleEditOrder,
                                          refreshOrders,
                                          setErrorMessage,
                                          setSuccessMessage,

                                      }) => {

    const [confirming, setConfirming] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        title: '',
        confirmationText: '',
        action: null,
        inputRequired: false,
        placeholder: '',
    });
    const [remarks, setRemarks] = useState('');


    const handleConfirmAction = async () => {
        setIsConfirmModalVisible(false);
        if (modalConfig.action) {
            setConfirming(true);
            await handleOrderAction({
                orderId,
                action: modalConfig.action,
                setOrderDetails,
                setSuccessMessage,
                setErrorMessage,
                setConfirming,
                refreshOrders,
                remarks,
            });
            setRemarks('');
        }
    };


    // Button visibility conditions based on order status
    const showConfirmButton = orderDetails.status === 'draft';
    const showApproveRejectButtons =
        orderDetails.status === 'awaiting_approval' && !orderDetails.is_approved;
    const showExecuteButton = orderDetails.status === 'ready_to_execute';
    const showGenerateInvoiceButton = orderDetails.status === 'ready_to_invoice';
    const showDispatchButton = orderDetails.status === 'ready_to_dispatch';
    const showEditButton =
        orderDetails.status === 'draft' || orderDetails.status === 'rejected';


    // Function to show the confirmation modal
    const showModal = (action, title, confirmationText, inputRequired = false, placeholder = '') => {
        setModalConfig({action, title, confirmationText, inputRequired, placeholder});
        setIsConfirmModalVisible(true);
    };


    return (
        <>
            <Space wrap>
                {/* Confirm */}
                {showConfirmButton && (
                    <Button
                        type="primary"
                        icon={<FileDoneOutlined/>}
                        style={{backgroundColor: '#1890ff', borderColor: '#1890ff'}}
                        loading={confirming}
                        onClick={() =>
                            showModal('confirm', 'Confirm Sale Order', 'Are you sure you want to confirm this sale order?')
                        }
                    >
                        Confirm
                    </Button>
                )}

                {/* Approve */}
                {showApproveRejectButtons && (
                    <Button
                        type="default"
                        icon={<CheckOutlined/>}
                        style={{color: '#52c41a', borderColor: '#52c41a'}}
                        onClick={() =>
                            showModal('approve', 'Approve Sale Order', 'Are you sure you want to approve this sale order?')
                        }
                    >
                        Approve
                    </Button>
                )}

                {/* Reject */}
                {showApproveRejectButtons && (
                    <Button
                        type="danger"
                        icon={<CloseOutlined/>}
                        style={{color: '#f5222d', borderColor: '#f5222d'}}
                        onClick={() =>
                            showModal(
                                'reject',
                                'Reject Sale Order',
                                'Are you sure you want to reject this sale order?',
                                true,
                                'Enter rejection reason'
                            )
                        }
                    >
                        Reject
                    </Button>
                )}

                {/* Execute */}
                {showExecuteButton && (
                    <Button
                        type="default"
                        icon={<CarryOutOutlined/>}
                        style={{color: '#2f54eb', borderColor: '#2f54eb'}}
                        onClick={() =>
                            showModal(
                                'execute',
                                'Execute Sale Order',
                                'Are you sure you want to Execute this sale order?')
                        }
                    >
                        Execute
                    </Button>
                )}

                {/* Generate Invoice */}
                {showGenerateInvoiceButton && (
                    <Button
                        type="default"
                        icon={<DollarOutlined/>}
                        style={{color: '#13c2c2', borderColor: '#13c2c2'}}
                        onClick={() =>
                            showModal(
                                'generateInvoice',
                                'Generate Invoice',
                                'Are you sure you want to generate an invoice for this sale order?'
                            )
                        }
                    >
                        Generate Invoice
                    </Button>
                )}

                {/* Dispatch */}
                {showDispatchButton && (
                    <Button
                        type="default"
                        icon={<FileProtectOutlined/>}
                        style={{color: '#1890ff', borderColor: '#1890ff'}}
                        onClick={() =>
                            showModal(
                                'dispatch',
                                'Dispatch Sale Order',
                                'Are you sure you want to dispatch this sale order?'
                            )
                        }
                    >
                        Dispatch
                    </Button>
                )}

                {/* Edit */}
                {showEditButton && (
                    <Button
                        type="default"
                        onClick={() => handleEditOrder(orderDetails)}
                        icon={<EditOutlined/>}
                        style={{marginRight: 10}}
                    >
                        Edit
                    </Button>
                )}
            </Space>
            {/* Confirmation Modal */}
            <ConfirmActionModal
                visible={isConfirmModalVisible}
                onConfirm={handleConfirmAction}
                onCancel={() => setIsConfirmModalVisible(false)}
                title={modalConfig.title}
                confirmationText={modalConfig.confirmationText}
                inputRequired={modalConfig.inputRequired}
                inputValue={remarks}
                setInputValue={setRemarks}
                placeholder={modalConfig.placeholder}
                loading={confirming}
            />
        </>
    );
};

export default OrderActionsForDetailsDrawer;
