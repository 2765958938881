// src/components/ProductList.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Card, Spin, message } from 'antd';
import {fetchItemsApi} from "./productSlice";

const ProductList = () => {
  const dispatch = useDispatch();
  // Safely access the state properties
  const { allItems = [], loading, hasFetched } = useSelector((state) => state.products || {});


  useEffect(() => {
    // Fetch items only if they haven't been fetched yet
    if (!hasFetched) {
      dispatch(fetchItemsApi(1)).catch((error) => {
        message.error('Failed to load products');
      });
    }
  }, [dispatch, hasFetched]);

  if (loading && !allItems.length) return <Spin tip="Loading products..." />;

  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={allItems}
      renderItem={(item) => (
        <List.Item>
          <Card title={item.item_code}>
            <p>Item Code: {item.item_code}</p>
            <p>HSN Code: {item.hsn_code}</p>
            {item.media && item.media[0]?.image && (
              <img src={item.media[0].image} alt={item.item_name} style={{ width: '100%' }} />
            )}
          </Card>
        </List.Item>
      )}
    />
  );
};

export default ProductList;
