// src/components/StatesTable.js
import React from 'react';
import { Table } from 'antd';

const StatesTable = ({ data, loading, pagination, onTableChange }) => {
    const columns = [
        {
            title: 'State Name',
            dataIndex: 'state_name',
            key: 'state_name',
            sorter: (a, b) => a.state_name.localeCompare(b.state_name),
        },
        {
            title: 'State Code',
            dataIndex: 'state_code',
            key: 'state_code',
        },
        {
            title: 'TIN Number',
            dataIndex: 'tin_number',
            key: 'tin_number',
        },
        {
            title: 'Country',
            dataIndex: ['country', 'country_name'],
            key: 'country_name',
        },
        {
            title: 'Country Code',
            dataIndex: ['country', 'country_code'],
            key: 'country_code',
        },
        {
            title: 'ISD Code',
            dataIndex: ['country', 'isd_code'],
            key: 'isd_code',
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            onChange={onTableChange}
            bordered
        />
    );
};

export default StatesTable;
