import React, {useEffect, useState} from 'react';
import {Button, Empty, Input, Select, Table, Tag, Tooltip} from 'antd';
import {fetchStocksApi} from './inventoryAPI';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCompanies} from '../../store/slices/companyBranchSlice';
import {BarcodeOutlined, ClearOutlined, FilterOutlined} from "@ant-design/icons";
import {truncateText} from '../../utils/utils';
import {fetchBrandsApi} from "../products/productSlice";

const {Option} = Select;

const InventoryStockList = ({setErrorMessage, setSuccessMessage}) => {
    const dispatch = useDispatch();
    const {companies, loading: companiesLoading} = useSelector((state) => state.companyBranch);
    const {brands = [], brandsLoading, hasFetchedBrands} = useSelector((state) => state.products);

    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});

    // Temporary filter states
    const [tempBrand, setTempBrand] = useState('');
    const [tempItemCode, setTempItemCode] = useState('');
    const [tempCompany, setTempCompany] = useState('');
    const [tempBranch, setTempBranch] = useState('');

    const [filteredBrands, setFilteredBrands] = useState([]);
    const [selectedBrandName, setSelectedBrandName] = useState('');
    const [companyBranches, setCompanyBranches] = useState([]);

    // Fetch companies and brands on mount
    useEffect(() => {
        // Dispatch fetchCompanies - it will check internally if fetching is necessary
        dispatch(fetchCompanies());

        if (!hasFetchedBrands) {
        const storedBrands = localStorage.getItem('brands');
        if (storedBrands) {
            try {
                const parsedBrands = JSON.parse(storedBrands);
                if (Array.isArray(parsedBrands) && parsedBrands.length > 0) {
                    return; // No need to dispatch if brands are already in localStorage
                }
            } catch (error) {
                console.error('Error parsing brands from localStorage', error);
            }
        }

        dispatch(fetchBrandsApi());
    }
    }, [dispatch, companies, hasFetchedBrands]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === tempCompany);
        setCompanyBranches(company ? company.branches : []);
    }, [tempCompany, companies]);

    // Fetch stocks
    const fetchStocks = async (page = 1, brand = '', itemCode = '', company = '', branch = '') => {
        setLoading(true);
        try {
            const data = await fetchStocksApi(page, company, branch, itemCode, brand);
            setStocks(data.results);
            setPagination((prev) => ({
                ...prev,
                total: data.count,
                current: page,
            }));
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch stocks when pagination changes
    useEffect(() => {
        fetchStocks(pagination.current, tempBrand, tempItemCode, tempCompany, tempBranch);
    }, [pagination.current]);

    // Handle Apply Filters
    const handleApplyFilters = () => {
        setPagination((prev) => ({...prev, current: 1})); // Reset to first page
        fetchStocks(1, tempBrand, tempItemCode, tempCompany, tempBranch); // Use temporary filters directly
    };

    // Handle Clear Filters
    const handleClearFilters = () => {
        setTempBrand('');
        setTempItemCode('');
        setTempCompany('');
        setTempBranch('');
        setSelectedBrandName('');
        setPagination((prev) => ({...prev, current: 1})); // Reset to first page
        fetchStocks(1, '', '', '', ''); // Reset API call with empty filters
    };

    // Handle brand search
    const handleBrandSearch = (searchText) => {
        if (searchText) {
            const filtered = brands.filter((brand) =>
                brand.brand_name.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredBrands(filtered);
        } else {
            setFilteredBrands([]);
        }
    };

    // Handle brand select
    const handleBrandSelect = (value) => {
        const brand = brands.find((b) => b.id.toString() === value);
        setTempBrand(value);
        setSelectedBrandName(brand?.brand_name || '');
    };

    const columns = [
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: 'brand_name',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 15)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Item Code',
            dataIndex: 'item_code',
            key: 'item_code',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'available_quantity',
            key: 'available_quantity',
            align: 'right',
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
    ];

    return (
        <div>
            {/* Filters */}
            <div style={{display: 'flex', gap: '16px', marginBottom: '16px'}}>
                <Select
                    showSearch
                    placeholder="Search Brand"
                    optionFilterProp="children"
                    value={brands.find((b) => b.id === tempBrand)?.brand_name || undefined} // Dynamically get brand name
                    onChange={(value) => {
                        setTempBrand(value);
                        const brand = brands.find((b) => b.id === value);
                        setSelectedBrandName(brand?.brand_name || '');
                    }}
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No brands found."
                        />
                    }
                    style={{width: 200}}
                >
                    {brands.map((brand) => (
                        <Option key={brand.id} value={brand.id}>
                            {brand.brand_name}
                        </Option>
                    ))}
                </Select>


                <Input
                    placeholder="Enter Item Code"
                    value={tempItemCode}
                    onChange={(e) => setTempItemCode(e.target.value)}
                    style={{width: '200px'}}
                    prefix={<BarcodeOutlined/>}
                />

                <Select
                    placeholder="Select Company"
                    value={tempCompany || undefined}
                    onChange={(value) => setTempCompany(value)}
                    style={{width: '200px'}}
                    allowClear
                >
                    {companies.map((company) => (
                        <Option key={company.id} value={company.id}>
                            {company.company_name}
                        </Option>
                    ))}
                </Select>

                <Select
                    placeholder="Select Branch"
                    value={tempBranch || undefined}
                    onChange={(value) => setTempBranch(value)}
                    style={{width: '200px'}}
                    allowClear
                    disabled={!tempCompany}
                >
                    {companyBranches.map((branch) => (
                        <Option key={branch.id} value={branch.id}>
                            {branch.branch_name}
                        </Option>
                    ))}
                </Select>

                <Button
                    type="primary"
                    icon={<FilterOutlined/>}
                    onClick={handleApplyFilters}
                >
                    Apply Filters
                </Button>

                <Button
                    icon={<ClearOutlined/>}
                    onClick={handleClearFilters}
                    style={{
                        backgroundColor: '#f5222d',
                        borderColor: '#f5222d',
                        color: '#fff',
                    }}
                >
                    Clear Filters
                </Button>
            </div>

            <div style={{marginBottom: '16px'}}>
                {tempBrand && <Tag>Brand: {brands.find((b) => b.id === tempBrand)?.brand_name}</Tag>}
                {tempItemCode && <Tag>Item Code: {tempItemCode}</Tag>}
                {tempCompany && (
                    <Tag>Company: {companies.find((c) => c.id === tempCompany)?.company_name}</Tag>
                )}
                {tempBranch && (
                    <Tag>Branch: {companyBranches.find((branch) => branch.id === tempBranch)?.branch_name}</Tag>
                )}
            </div>

            {/* Table */}
            <Table
                columns={columns}
                dataSource={stocks}
                loading={loading}
                pagination={{
                    ...pagination,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={(pagination) => setPagination({current: pagination.current, pageSize: pagination.pageSize})}
                rowKey="item_id"
                size="small"
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No items available."
                        />
                    ),
                }}
            />
        </div>
    );
};

export default InventoryStockList;
