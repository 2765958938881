import React, {useEffect, useState} from 'react';
import {Card, Col, Grid, message, Row, Spin, Table, Tooltip} from 'antd';
import {
    fetchBestSellingItems,
    fetchBranchPerformance,
    fetchBranchStatusOpenOrders,
    fetchOrderStatusCount,
    fetchRevenueGrowthAnalysis
} from '../features/analytics/salesAnalyticsApi';
import {BarChartOutlined, ShopOutlined} from '@ant-design/icons';
import {formatRupee, truncateText} from "../utils/utils";
import PivotTable from "../features/analytics/PivotTable";
import RevenueAnalysisCard from "../features/analytics/RevenueAnalysisCard";
import BranchPerformanceTable from "../features/analytics/BranchPerformanceTable";

const {useBreakpoint} = Grid;

// const mockRevenueGrowth = {
//     current_month_revenue: "2000000.00", // Current month's revenue
//     last_month_revenue: "1379310.34",    // Last month's revenue (45% lower)
//     monthly_growth_percentage: 45,       // 45% growth compared to last month
//
//     current_quarter_revenue: "5000000.00", // Current quarter's revenue
//     last_quarter_revenue: "5555555.56",    // Last quarter's revenue (10% higher)
//     quarterly_growth_percentage: -10       // 10% decline compared to last quarter
// };

const AnalyticsDashboard = () => {
    const screens = useBreakpoint();
    const [loading, setLoading] = useState(true);
    const [bestSellingItems, setBestSellingItems] = useState([]);
    const [branchPerformance, setBranchPerformance] = useState([]);
    const [orderStatusCount, setOrderStatusCount] = useState([]);
    const [branchStatusOpenOrders, setBranchStatusOpenOrders] = useState([]);
    const [revenueGrowth, setRevenueGrowth] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const [bestItems, branchPerf, orderStatus, branchOrders, revenue] = await Promise.all([
                    fetchBestSellingItems(),
                    fetchBranchPerformance(),
                    fetchOrderStatusCount(),
                    fetchBranchStatusOpenOrders(),
                    fetchRevenueGrowthAnalysis()
                ]);
                setBestSellingItems(bestItems);
                setBranchPerformance(branchPerf);
                setOrderStatusCount(orderStatus);
                setBranchStatusOpenOrders(branchOrders);
                setRevenueGrowth(revenue);
            } catch (error) {
                message.error('Error loading data');
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    if (loading) return <Spin style={{marginTop: 50}}/>;

    return (
        <div style={{padding: '20px'}}>
            {/* Revenue Analysis */}
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {/*<RevenueAnalysisCard revenueGrowth={revenueGrowth} />*/}
                    <RevenueAnalysisCard revenueGrowth={revenueGrowth}/>
                </Col>
            </Row>

            {/* Best Selling Items */}
            <Row gutter={[16, 16]} style={{marginTop: '20px'}}>
                <Col span={24}>
                    <Card title={<><ShopOutlined/> Best Selling Items</>} style={{height: '100%'}}>
                        <Table
                            dataSource={bestSellingItems.map((item, index) => ({...item, key: index}))}
                            columns={[
                                {
                                    title: 'Item Code',
                                    dataIndex: 'item_code',
                                    key: 'item_code',
                                    ellipsis: true,
                                    render: (text) => (
                                        <Tooltip title={text || '-'}>
                                            <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                                        </Tooltip>
                                    ),
                                },
                                {title: 'Brand', dataIndex: 'brand_name', key: 'brand_name'},
                                {
                                    title: 'Quantity Sold',
                                    dataIndex: 'total_quantity',
                                    key: 'total_quantity',
                                    align: 'center'
                                },
                                {
                                    title: 'Revenue',
                                    dataIndex: 'total_revenue',
                                    key: 'total_revenue',
                                    align: 'right',
                                    render: (value) => formatRupee(value),
                                },
                            ]}
                            pagination={false}
                            scroll={{y: 300}}
                            size="small"
                        />

                    </Card>
                </Col>
            </Row>

            {/* Branch Performance and Order Status Count */}
            <Row gutter={[16, 16]} style={{marginTop: '20px'}}>

                <BranchPerformanceTable branchPerformance={branchPerformance}/>
            </Row>
            <Row gutter={[16, 16]} style={{marginTop: '20px'}}>


                <Col xs={24} md={12}>
                    <Card title={<><BarChartOutlined/> Order Status Count</>} style={{height: '100%'}}>
                        <Table
                            dataSource={orderStatusCount.map((status, index) => ({...status, key: index}))}
                            columns={[
                                {title: 'Status', dataIndex: 'status_display', key: 'status_display'},
                                {title: 'Order Count', dataIndex: 'count', key: 'count', align: 'center'},
                                {
                                    title: 'Order Value',
                                    dataIndex: 'total_order_value',
                                    key: 'total_order_value',
                                    align: 'right',
                                    render: (value) => formatRupee(value),
                                },
                            ]}
                            pagination={false}
                            size="small"
                        />

                    </Card>
                </Col>
            </Row>

            {/* Branch & Status Wise Open Orders Pivot Table */}
            <Row gutter={[16, 16]} style={{marginTop: '20px'}}>
                <Col span={24}>
                    <Card title="Branch & Status Wise Open Orders" style={{height: '100%'}}>
                        <PivotTable data={branchStatusOpenOrders}/>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AnalyticsDashboard;
