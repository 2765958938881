import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Input, Button, Row, Col, Select, Checkbox, Divider, message, Empty} from 'antd';
import {registerCustomerApi} from './customerApi';
import {fetchCountriesWithStates, fetchCustomerSegments, fetchTerritories} from "../../store/slices/locationSlice";

const {Option} = Select;

const CustomerRegistration = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const {countries, territories, customerSegments} = useSelector((state) => state.configuration);

    const [states, setStates] = useState([]);
    const [isBusiness, setIsBusiness] = useState(false);
    const [isRegisteredBusiness, setIsRegisteredBusiness] = useState(false);
    const [loading, setLoading] = useState(false);

    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (countries.length === 0) dispatch(fetchCountriesWithStates());
        if (territories.length === 0) dispatch(fetchTerritories());
        if (customerSegments.length === 0) dispatch(fetchCustomerSegments());
    }, [dispatch, countries, territories]);

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    const handleCountryChange = (countryId) => {
        const selectedCountry = countries.find((country) => country.id === countryId);
        setStates(selectedCountry?.states || []);
        form.setFieldsValue({state: null});
    };

    const handleCustomerTypeChange = (value) => setIsBusiness(value === 'business');

    const handleRegister = async (values) => {
        setLoading(true);
        console.log('Submitting customer registration:', values);

        // Transform form data to match the API requirements
        const requestData = {
            ...values,
            customer_segment_id: values.customer_segment, // Map customer_segment to customer_segment_id
            territory_id: values.territory, // Map territory to territory_id
        };

        try {
            const response = await registerCustomerApi(requestData);
            console.log('API Response:', response);
            setSuccessMessage('Customer registered successfully');
            form.resetFields();
            setIsBusiness(false);
            setIsRegisteredBusiness(false);
        } catch (error) {
            console.error('API Error:', error);
            setErrorMessage('Failed to register customer');
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        form.resetFields();
        setIsBusiness(false);
        setIsRegisteredBusiness(false);
        setStates([]);
    };

    return (
        <div style={{padding: '10px', maxWidth: '1000px', margin: '0 auto'}}>
            {contextHolder}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleRegister}
                initialValues={{customer_type: 'individual', is_registered_business: false}}
                style={{padding: '0', margin: '0'}}
            >
                {/* Basic Information */}
                <Divider style={{margin: '2px 0'}}>Basic Information</Divider>
                <Row gutter={[8, 8]} style={{marginBottom: '8px'}}>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="Customer Type" name="customer_type" rules={[{required: true}]}>
                            <Select onChange={handleCustomerTypeChange}>
                                <Option value="individual">Individual</Option>
                                <Option value="business">Business</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Customer Segment" name="customer_segment"
                                   rules={[{required: true, message: 'Select Customer Segment'}]}>
                            <Select placeholder="Select Customer Segment">
                                {customerSegments.map((segment) => (
                                    <Option key={segment.id} value={segment.id}>{segment.customer_segment}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[8, 8]} style={{marginBottom: '8px'}}>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Customer Name" name="name"
                                   rules={[{required: true, message: 'Enter Customer Name'}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Mobile Number" name="mobile_number" rules={[{
                            required: true,
                            pattern: /^[0-9]{10}$/,
                            message: 'Enter a valid 10-digit number'
                        }]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Email" name="email" rules={[{type: 'email'}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Business Information */}
                {isBusiness && (
                    <>
                        <Divider style={{margin: '2px 0'}}>Business Information</Divider>
                        <Row gutter={[8, 8]} align="middle">
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item label="Contact Person" name="contact_person"
                                           rules={[{required: true, message: 'Enter Contact Person'}]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Form.Item name="is_registered_business" valuePropName="checked"
                                           style={{marginBottom: 0}}>
                                    <Checkbox onChange={(e) => setIsRegisteredBusiness(e.target.checked)}>Registered
                                        Business?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        {isRegisteredBusiness && (
                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="GSTIN" name="gstin"
                                               rules={[{required: true, len: 15, message: 'Enter GSTIN'}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item label="PAN" name="pan" rules={[{pattern: /^[A-Z]{5}[0-9]{4}[A-Z]$/}]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </>
                )}

                {/* Address Information */}
                <Divider style={{margin: '2px 0'}}>Address Information</Divider>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Address Line 1" name="address_line_1">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="Address Line 2" name="address_line_2">
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item label="City" name="city">
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="Country" name="country" rules={[{required: true, message: 'Select Country'}]}>
                            <Select
                                showSearch
                                placeholder="Select Country"
                                optionFilterProp="children"
                                onChange={handleCountryChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No countries found."
                                    />
                                }
                            >
                                {countries.map((country) => (
                                    <Option key={country.id} value={country.id}>{country.country_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="State" name="state" rules={[{required: true, message: 'Select State'}]}>
                            <Select
                                showSearch
                                placeholder="Select State"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Select Country."
                                    />
                                }
                            >
                                {states.map((state) => (
                                    <Option key={state.id} value={state.id}>{state.state_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item label="Territory" name="territory"
                                   rules={[{required: true, message: 'Select Territory'}]}>
                            <Select
                                showSearch
                                placeholder="Select Territory"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No territories found."
                                    />
                                }
                            >
                                {territories.map((territory) => (
                                    <Option key={territory.id} value={territory.id}>{territory.territory}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="center" gutter={[8, 8]}>
                    <Col>
                        <Button type="primary" htmlType="submit" loading={loading}>Register</Button>
                    </Col>
                    <Col>
                        <Button onClick={handleReset}>Reset</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CustomerRegistration;
