// serialNumberUtils.js
import {Typography} from 'antd';
import {fetchSerialNumberDetails} from "../inventory/inventoryAPI";

const {Text} = Typography;

/**
 * Validates a serial number for sales transaction.
 *
 * @param {string} serialNumber - The serial number to validate.
 * @param {array} existingSerialNumbers - List of already added serial numbers.
 * @param {number} quantityRequired - The required number of serial numbers.
 * @param {string} itemId - The item ID of the transaction item.
 * @param {number} companyId - The company ID of the current branch.
 * @param {number} branchId - The branch ID of the current branch.
 * @returns {Promise<string[]>} - A promise that resolves to an array of error messages (empty if valid).
 */
export const validateSerialNumberForSales = async (serialNumber, existingSerialNumbers, quantityRequired, itemId, companyId, branchId) => {
    const errors = [];

    // Check if the serial number is already added
    if (existingSerialNumbers.includes(serialNumber)) {
        errors.push(<>
            Serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text> is already added.
        </>);
        return errors;
    }

    // Check if the total quantity exceeds the required number
    if (existingSerialNumbers.length >= quantityRequired) {
        errors.push(<>
            You can only add <Text strong style={{color: '#ff4d4f'}}>{quantityRequired}</Text> serial numbers.
        </>);
        return errors;
    }

    try {
        // Fetch the serial number details from the API
        const serialData = await fetchSerialNumberDetails(serialNumber);

        // Validate the serial number is available
        if (serialData.status !== 'available') {
            errors.push(<>
                Serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text> is not available.
            </>);
        }

        // Validate the serial number is associated with the same item
        if (serialData.item_db_id !== itemId) {
            console.log("ItemId: ", itemId, " --- item_id_db", serialData.item_db_id);
            errors.push(<>
                Serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text> is not associated with
                the same item.
            </>);
        }

        // Validate the serial number belongs to the same company and branch
        if (serialData.company_id !== companyId) {
            errors.push(<>
                Serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text> does not belong to the
                same company.
            </>);
        }
        if (serialData.branch_id !== branchId) {
            errors.push(<>
                Serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text> does not belong to the
                same branch.
            </>);
        }
    } catch (error) {
        // Handle API errors
        if (error.response && error.response.status === 404) {
            errors.push(<>
                Serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text> does not exist.
            </>);
        } else {
            errors.push(<>
                Failed to validate serial number <Text strong style={{color: '#ff4d4f'}}>{serialNumber}</Text>.
                Please try again.
            </>);
        }
    }

    return errors;
};


export const submitSerialNumbersForSales = async (transactionItemId, serialNumbers, branchId, companyId) => {
    // Example API call
    try {
        const response = await fetch('/api/sales/submit-serial-numbers', {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({
                transactionItemId, serialNumbers, branchId, companyId,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to submit serial numbers.');
        }

        const result = await response.json();
        return {success: true, message: result.message};
    } catch (error) {
        return {success: false, message: error.message};
    }
};
