import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { refreshItems } from '../features/products/productSlice';
import {refreshCompanies} from "../store/slices/companyBranchSlice";
import ConfirmActionModal from "../features/common/ConfirmActionModal";

const RefreshLocalStorage = () => {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [currentButton, setCurrentButton] = useState(null);

    const buttons = [
        {
            key: 'refreshItems',
            text: 'Refresh Items',
            action: refreshItems,
            title: 'Are you sure you want to refresh the items list?',
            content: 'This action will reload the items list from the server and cannot be undone.',
            danger: true,
        },
        {
            key: 'refreshCompanies',
            text: 'Refresh Companies',
            action: refreshCompanies,
            title: 'Are you sure you want to refresh the companies list?',
            content: 'This action will reload the companies list from the server and cannot be undone.',
            danger: false,
        },
        {
            key: 'action2',
            text: 'Perform Action 2',
            action: refreshItems, // This should ideally be a different action
            title: 'Confirm Action 2?',
            content: 'Performing this action will change some data.',
            danger: true,
        },
    ];

    const showModal = (button) => {
        setCurrentButton(button);
        setModalVisible(true);
    };

    const handleConfirm = () => {
        dispatch(currentButton.action());
        setModalVisible(false);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <div>
            {buttons.map(button => (
                <Button
                    key={button.key}
                    type="primary"
                    danger={button.danger}
                    onClick={() => showModal(button)}
                    style={{ marginRight: 8 }}
                >
                    {button.text}
                </Button>
            ))}
            <ConfirmActionModal
                visible={modalVisible}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                title={currentButton?.title}
                confirmationText={currentButton?.content}
                confirmButtonText={currentButton?.text}
                inputRequired={false} // Set to true and manage input if needed
            />
        </div>
    );
};

export default RefreshLocalStorage;
