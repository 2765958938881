import React from 'react';
import {Button, Card, Descriptions, Table, Tooltip, Typography} from 'antd';
import {formatRupee, truncateText} from '../../utils/utils';
import {QrcodeOutlined} from "@ant-design/icons";

const {Text} = Typography;

const PurchaseReceiptReview = ({receiptData, items, onEdit, onSubmit, onCancel}) => {
    const columns = [
        {
            title: 'Item Details',
            key: 'item_details',
            render: (_, record) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '12px', padding: '4px 0'}}>
                    <div style={{flex: 1}}>
                        {/* Item Code and Brand Name */}
                        <div style={{fontWeight: 'bold', fontSize: '14px', lineHeight: '1.2'}}>
                            <Tooltip title={record.item_code || 'N/A'}>
                                {truncateText(record.item_code || 'N/A', 35)} ({record.brand_name || 'N/A'})
                            </Tooltip>
                        </div>
                        {/* Item Name */}
                        <div style={{color: 'gray', fontSize: '12px', marginTop: '4px'}}>
                            <Tooltip title={record.item_name || 'N/A'}>
                                {truncateText(record.item_name || 'N/A', 35)}
                            </Tooltip>
                        </div>
                    </div>
                    {/* QR Code Icon for Serialized Items */}
                    {record.has_serial_no && (
                        <Tooltip title="This is a serialized item">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f0f5ff', // Light complementary background
                                    border: '1px solid #adc6ff', // Softer complementary border
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '8px',
                                }}
                            >
                                <QrcodeOutlined style={{fontSize: '16px', color: '#597ef7'}}/>
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'right',
        },
        {
            title: 'Item Price',
            dataIndex: 'item_price',
            align: 'right',
            render: (price) => formatRupee(price),
        },
        {
            title: 'Total Amount',
            render: (_, record) => formatRupee(record.totalAmount),
            align: 'right',
        },
    ];

    return (
        <div style={{padding: '8px'}}>
            {/* Receipt Details */}
            <Card title="Receipt Details" bordered style={{marginBottom: '8px'}}>
                <Descriptions bordered size="small" column={2}>
                    {/* Supplier and Recipient - First Row */}
                    <Descriptions.Item label="Supplier" span={1}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <strong>{receiptData.supplier?.company?.name || 'N/A'}</strong>
                            <span style={{color: 'gray'}}>{receiptData.supplier?.branch?.name || 'N/A'}</span>
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Recipient" span={1}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <strong>{receiptData.recipient?.company?.name || 'N/A'}</strong>
                            <span style={{color: 'gray'}}>{receiptData.recipient?.branch?.name || 'N/A'}</span>
                        </div>
                    </Descriptions.Item>

                    {/* Invoice Details - Second Row */}
                    <Descriptions.Item label="Invoice Date">
                        {receiptData.invoiceDetails?.invoiceDate || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Invoice Number">
                        {receiptData.invoiceDetails?.invoiceNumber || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Invoice Amount">
                        {formatRupee(receiptData.invoiceDetails?.amount || 0)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Due Date">
                        {receiptData.invoiceDetails?.dueDate || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax Inclusion">
                        {receiptData.isInclusiveTax ? 'Inclusive of Tax' : 'Exclusive of Tax'}
                    </Descriptions.Item>
                </Descriptions>

            </Card>

            {/* Item Details */}
            <Card title="Item Details" bordered>
                <Table
                    columns={columns}
                    dataSource={items}
                    rowKey="id"
                    pagination={false}
                    size="small"
                />
            </Card>

            {/* Total Summary */}
            <Card bordered style={{marginTop: '8px'}}>
                <Descriptions bordered size="small" column={2}>
                    <Descriptions.Item label="Total Quantity">
                        {receiptData.totalQuantity || 0}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Amount">
                        {formatRupee(receiptData.totalAmount || 0)}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {/* Action Buttons */}
            <div
                style={{
                    marginTop: '8px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '12px',
                }}
            >
                <Button onClick={onEdit}>Edit</Button>
                <Button onClick={onCancel} type="default">
                    Cancel
                </Button>
                <Button onClick={onSubmit} type="primary">
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default PurchaseReceiptReview;
