// src/pages/CountriesList.js
import React, { useEffect, useState } from 'react';
import {Button, Input, message} from 'antd';
import {createCountryApi, fetchCountriesApi, updateCountryApi} from "./countriesApi";
import CountriesTable from "./CountriesTable";
import CountryFormModal from "./CountryFormModal";

const { Search } = Input;

const CountriesList = () => {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [searchTerm, setSearchTerm] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const user = JSON.parse(localStorage.getItem('user')); // Retrieve user data
    const userRole = user?.user_role; // Extract user role


    const fetchCountries = async (page = 1, search = '') => {
        setLoading(true);
        try {
            const data = await fetchCountriesApi(page, search);
            setCountries(data.results);
            setPagination((prev) => ({
                ...prev,
                total: data.count,
                current: page,
            }));
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCountries(pagination.current, searchTerm);
    }, [pagination.current, searchTerm]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPagination((prev) => ({ ...prev, current: 1 }));
    };

    const handleTableChange = (pagination) => {
        setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleAddCountry = () => {
        if (userRole === 'super_admin') {
            console.log('Add Country Modal Triggered');
            setSelectedCountry(null);
            setModalVisible(true);
            // Add your modal logic here
        } else {
            message.error('You do not have permission to add a country');
        }

    };

    const handleEditCountry = (country) => {
        setSelectedCountry(country);
        setModalVisible(true);
    };

    const handleModalSubmit = async (countryData) => {
        if (selectedCountry) {
            // Update existing country
            await updateCountryApi(selectedCountry.id, countryData);
        } else {
            // Create new country
            await createCountryApi(countryData);
        }
        setModalVisible(false);
        fetchCountries(pagination.current, searchTerm);
    };

    return (
        <div style={{padding: '24px'}}>
            <h2>Countries List</h2>
            <div style={{marginBottom: '16px', display: 'flex', justifyContent: 'space-between'}}>
                <Search
                    placeholder="Search by country name"
                    onSearch={handleSearch}
                    style={{width: '300px'}}
                    enterButton
                />
                <Button
                    type="primary"
                    onClick={handleAddCountry}
                    disabled={userRole !== 'super_admin'}
                >
                    Add Country
                </Button>
            </div>
            <CountriesTable
                data={countries}
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
                onTableChange={handleTableChange}
                onEditCountry={handleEditCountry}
                userRole={userRole}
            />
            <CountryFormModal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onSubmit={handleModalSubmit}
                country={selectedCountry}
            />
        </div>
    );
};

export default CountriesList;
