import React, {useEffect, useState} from 'react';
import { Input, Tag, Typography, message, Divider, Modal, Row, Col } from 'antd';
import {submitSerialNumbersForSales, validateSerialNumberForSales} from "./serialNumberUtils";

const { Text } = Typography;

const SerialNumberInputModal = ({
    visible,
    onClose,
    transactionType,
    orderNumber,
    transactionItemId,
    itemCode,
    itemId,
    quantity,
    quantityRequired,
    branchId,
    companyId,
    existingSerialNumbers = [],
    onSerialNumberChange,
}) => {
    // const [serialNumbers, setSerialNumbers] = useState(existingSerialNumbers);
    const [serialNumbers, setSerialNumbers] = useState(Array.isArray(existingSerialNumbers) ? existingSerialNumbers : []);

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);

    useEffect(() => {
        // Validate and set existingSerialNumbers
        if (Array.isArray(existingSerialNumbers)) {
            setSerialNumbers(existingSerialNumbers);
        } else {
            console.warn('existingSerialNumbers is not an array:', existingSerialNumbers);
            setSerialNumbers([]);
        }
    }, [existingSerialNumbers]);

    // Add a serial number
    const handleAddSerialNumber = async (value) => {
        const errors = await validateSerialNumberForSales(
        value.trim(),
        serialNumbers,
        quantityRequired,
        itemId,
        companyId,
        branchId
    );
        if (errors.length > 0) {
            errors.forEach((err) => message.warning(err));
            return;
        }

        const updatedSerialNumbers = [...serialNumbers, value.trim()];
        setSerialNumbers(updatedSerialNumbers);
        onSerialNumberChange(updatedSerialNumbers);
        setInputValue('');
    };

    // Remove a serial number
    const handleRemoveSerialNumber = (removedSerial) => {
        const updatedSerialNumbers = serialNumbers.filter((serial) => serial !== removedSerial);
        setSerialNumbers(updatedSerialNumbers);
        onSerialNumberChange(updatedSerialNumbers);
    };

    // Handle input submission
    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            handleAddSerialNumber(inputValue);
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        if (serialNumbers.length !== quantityRequired) {
            setErrorMessage(`Please enter exactly ${quantityRequired} serial numbers.`);
            return;
        }

        setLoading(true);

        const result = await submitSerialNumbersForSales(transactionItemId, serialNumbers, branchId, companyId);
        setLoading(false);

        if (result.success) {
            setSuccessMessage(result.message);
            onClose();
        } else {
            setErrorMessage(result.message);
        }
    };

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            onOk={handleSubmit}
            confirmLoading={loading}
            title="Serial Number Input"
            width={600}
        >
            {contextHolder}
            <div>
                {/* Transaction Info */}
                <Row gutter={16} style={{ marginBottom: '16px' }}>
                    <Col span={12}>
                        <Text type="secondary">Transaction Type:</Text>
                        <br />
                        <Text strong>{transactionType}</Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Order Number:</Text>
                        <br />
                        <Text strong>{orderNumber}</Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Transaction Item ID:</Text>
                        <br />
                        <Text strong>{transactionItemId}</Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Item Code:</Text>
                        <br />
                        <Text strong>{itemCode}</Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Quantities Required:</Text>
                        <br />
                        <Text strong>{quantityRequired}</Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Quantities Scanned:</Text>
                        <br />
                        <Text strong>{serialNumbers.length}</Text>
                    </Col>
                </Row>

                <Divider orientation="left">Serial Number Input</Divider>

                {/* Serial Number Input */}
                <div style={{ marginBottom: '16px' }}>
                    <Text strong>Serial Numbers:</Text>
                    <Input
                        placeholder="Enter serial numbers"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleInputKeyPress}
                        onBlur={() => handleAddSerialNumber(inputValue)} // Add serial on blur
                        style={{ marginTop: '8px', width: '100%' }}
                    />
                </div>

                {/* Display Tags */}
                <div style={{ marginTop: '8px' }}>
                    {serialNumbers.map((serial, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveSerialNumber(serial)}
                            style={{
                                marginBottom: '8px',
                                padding: '4px 8px',
                                fontSize: '14px',
                            }}
                        >
                            {serial}
                        </Tag>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default SerialNumberInputModal;
