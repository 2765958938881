import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Select, Button, Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../store/slices/employeeSlice";

const { Option } = Select;

const SalespersonSelector = ({ visible, onClose, onSelectSalesperson, currentSalesperson }) => {
    const dispatch = useDispatch();
    const { employees, loading } = useSelector((state) => state.employees);

    const [selectedSalesperson, setSelectedSalesperson] = useState(null);
    const [hasFetched, setHasFetched] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    // Set initial salesperson if provided
    useEffect(() => {
        if (visible) {
            setSelectedSalesperson(currentSalesperson || null);
        }
    }, [visible, currentSalesperson]);

    // Fetch employees only once when the modal is first opened
    useEffect(() => {
        if (visible && !hasFetched) {
            dispatch(fetchEmployees());
            setHasFetched(true);
        }
    }, [visible, hasFetched, dispatch]);

    // Reset the fetch flag when the modal is closed
    useEffect(() => {
        if (!visible) {
            setHasFetched(false);
        }
    }, [visible]);

    // Filter employees based on search input
    const filteredEmployees = useMemo(() => {
        if (!searchValue) return employees;
        const lowerValue = searchValue.toLowerCase();
        return employees.filter(
            (emp) =>
                emp.full_name.toLowerCase().includes(lowerValue) ||
                emp.employee_id.toLowerCase().includes(lowerValue) ||
                emp.company_code.toLowerCase().includes(lowerValue)
        );
    }, [employees, searchValue]);

    const handleSave = () => {
    if (selectedSalesperson) {
        onSelectSalesperson({
            id: selectedSalesperson.id,
            full_name: selectedSalesperson.full_name
        });
    }
    onClose();
};


    return (
        <Modal
            title="Select Salesperson"
            open={visible}
            onCancel={onClose}
            onOk={handleSave}
            cancelText="Cancel"
            okText="Save"
            destroyOnClose
            style={{ top: 40 }}
            centered={false}
        >
            {loading ? (
                <Spin />
            ) : (
                <Select
                    showSearch
                    value={selectedSalesperson?.id}
                    onChange={(value) => {
                        const selected = employees.find(emp => emp.id === value);
                        setSelectedSalesperson(selected);
                    }}
                    filterOption={false}
                    onSearch={setSearchValue}
                    style={{ width: '100%' }}
                    placeholder="Search by Name, ID, or Company Code"
                    optionLabelProp="label"
                    notFoundContent={loading ? <Spin /> : "No Salesperson Found"}
                >
                    {filteredEmployees.map((emp) => (
                        <Option
                            key={emp.id}
                            value={emp.id}
                            label={`${emp.employee_id} - ${emp.full_name} (${emp.company_code})`}
                        >
                            {`${emp.employee_id} - ${emp.full_name} (${emp.company_code})`}
                        </Option>
                    ))}
                </Select>
            )}
        </Modal>
    );
};

export default SalespersonSelector;
