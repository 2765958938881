import React from 'react';
import { Modal, Typography, Input, Button } from 'antd';

const { Text, Title } = Typography;

const ConfirmActionModal = ({
    visible,
    onConfirm,
    onCancel,
    title,
    confirmationText,
    confirmButtonText = 'Proceed',
    inputRequired = false,
    inputValue,
    setInputValue,
    placeholder = 'Enter remarks',
    loading = false,
}) => {
    const isConfirmDisabled = inputRequired && !inputValue.trim();
    return (
        <Modal
            open={visible}
            onOk={onConfirm}
            onCancel={onCancel}
            confirmLoading={loading}
            okText={confirmButtonText}
            cancelText="Cancel"
            centered
            maskClosable={false}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="confirm"
                    type="primary"
                    onClick={onConfirm}
                    loading={loading}
                    disabled={isConfirmDisabled} // Disable if input is empty
                >
                    {confirmButtonText}
                </Button>,
            ]}
        >
            <Title level={4}>{title}</Title>
            <Text>{confirmationText}</Text>
            {inputRequired && (
                <Input.TextArea
                    rows={4}
                    placeholder="Enter rejection reason"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ marginTop: 16 }}
                    required={inputRequired}
                />
            )}
        </Modal>
    );
};

export default ConfirmActionModal;
