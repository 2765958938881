import {
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import { hasAccess } from './uacUtils';

const menuDataRender = (userRole) => [
    {
        key: 'sub1',
        name: 'Sales',
        icon: <ShoppingCartOutlined />,
                children: [
                    { key: '/sales-dashboard', name: 'Dashboard', path: '/sales-dashboard', visible: hasAccess(userRole, 'sales-dashboard') },
                    { key: '/product-catalogue', name: 'Product Catalogue', path: '/product-catalogue', visible: hasAccess(userRole, 'product_catalogue') },
                    { key: '/sales', name: 'Sales', path: '/sales', visible: hasAccess(userRole, 'sales') },
                    { key: '/sales-orders', name: 'Sales Orders', path: '/sales-orders', visible: hasAccess(userRole, 'sales_orders') },
                    { key: '/register-customer', name: 'Register Customer', path: '/register-customer', visible: hasAccess(userRole, 'register_customer') },
                ].filter(item => item.visible !== false),
    },
    {
        key: 'inventory',
        name: 'Inventory',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: '/inventory', name: 'Inventory', path: '/inventory', visible: hasAccess(userRole, 'inventory') },
            { key: '/inventory-stock-list', name: 'Stock List', path: '/inventory-stock-list', visible: hasAccess(userRole, 'inventory-stock-list') },

        ].filter(item => item.visible !== false),
    },
    {
        key: 'procurement',
        name: 'Procurement',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: '/procurement', name: 'Purchase Orders', path: '/procurement', visible: hasAccess(userRole, 'procurement') },
            { key: '/procurement/purchase-requests/open', name: 'Open Requests', path: '/procurement/purchase-requests/open', visible: hasAccess(userRole, 'purchase_requests_open') },
            { key: '/procurement/purchase-requests/closed', name: 'Closed Requests', path: '/procurement/purchase-requests/closed', visible: hasAccess(userRole, 'purchase_requests_closed') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'sub2',
        name: 'Set Up',
        icon: <SettingOutlined />,
        children: [
            {
                key: 'g2',
                name: 'Config',
                type: 'group',
                children: [
                    { key: '/states_list', name: 'States List', path: '/states_list', visible: hasAccess(userRole, 'states_list') },
                    { key: '/countries_list', name: 'Countries List', path: '/countries_list', visible: hasAccess(userRole, 'countries_list') },
                ].filter(item => item.visible !== false),
            },
            {
                key: 'g3',
                name: 'Data Mgmt',
                type: 'group',
                children: [
                    { key: '/refresh_local_storage', name: 'Refresh Local', path: '/refresh_local_storage', visible: hasAccess(userRole, 'refresh_local_storage') },
                ].filter(item => item.visible !== false),
            },
        ],
    },
    {
        type: 'divider',
    },
    // {
    //     key: 'sales',
    //     name: 'Sales',
    //     icon: <ShoppingCartOutlined />,
    //     children: [
    //         { key: '/sales-orders', name: 'Sales Orders', path: '/sales-orders', visible: hasAccess(userRole, 'sales_orders') },
    //         { key: '/register-customer', name: 'Register Customer', path: '/register-customer', visible: hasAccess(userRole, 'register_customer') },
    //     ].filter(item => item.visible),
    // },
    {
        type: 'divider',
    },
    {
        key: 'sub4',
        name: 'Products',
        icon: <SettingOutlined />,
        children: [
            { key: '/products', name: 'Products', path: '/products', visible: hasAccess(userRole, 'products') },
            { key: '/transactions', name: 'Transactions', path: '/transactions', visible: hasAccess(userRole, 'transactions') },
        ].filter(item => item.visible !== false),
    },
].filter(item => item.children && item.children.length > 0);

export default menuDataRender;
