import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Button, Input, List, message, Spin, Space, Select, Row, Col, Typography, Empty, Tag } from 'antd';
import { ShoppingCartOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { fetchItemsApi, fetchBrandsApi } from './productSlice';
import { calculateDiscountPercentage, formatRupee, truncateText } from '../../utils/utils';

const { Option } = Select;
const { Text } = Typography;

const ProductDrawer = ({ visible, onClose, onSelectProduct, selectedProducts }) => {
    const dispatch = useDispatch();
    const { allItems = [], brands = [], loading, brandsLoading, hasFetched, hasFetchedBrands } = useSelector((state) => state.products);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (visible) {
            if (!hasFetched) {
                dispatch(fetchItemsApi(1));
            }
            if (!hasFetchedBrands) {
                dispatch(fetchBrandsApi());
            }
        }
    }, [dispatch, hasFetched, hasFetchedBrands, visible]);

    const handleAddProduct = (product) => {
        const isAlreadyAdded = selectedProducts.some((item) => item.id === product.id);

        if (isAlreadyAdded) {
            setInfoMessage(`${product.item_code} is already in the transaction`);
        } else {
            onSelectProduct(product);
            setSuccessMessage(`${product.item_code} added to transaction`);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleBrandChange = (value) => {
        setSelectedBrand(value);
    };

    const filteredItems = allItems.filter((item) => {
        const matchesSearch = item.item_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.item_code.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesBrand = selectedBrand ? item.brand === selectedBrand : true;
        return matchesSearch && matchesBrand;
    });

    useEffect(() => {
        if (successMessage) {
            messageApi.open({ type: 'success', content: successMessage, duration: 2.5 });
            setSuccessMessage(null);
        }
        if (infoMessage) {
            messageApi.open({ type: 'info', content: infoMessage, duration: 2.5 });
            setInfoMessage(null);
        }
        if (errorMessage) {
            messageApi.open({ type: 'error', content: errorMessage, duration: 2.5 });
            setErrorMessage(null);
        }
    }, [successMessage, infoMessage, errorMessage, messageApi]);

    return (
        <Drawer
            title="Select Products"
            placement="left"
            width="40%"
            onClose={onClose}
            open={visible}
            styles={{ body: { padding: '10px' } }}
        >
            {contextHolder}

            {/* Filters Row */}
            <Row gutter={16} style={{ position: 'sticky', top: 0, zIndex: 10, background: '#fff', padding: '8px 0' }}>
                <Col span={8}>
                    <Select
                        placeholder="Select Brand"
                        onChange={handleBrandChange}
                        allowClear
                        notFoundContent="Brands Not Found"
                        loading={brandsLoading}
                        style={{ width: '100%' }}
                    >
                        {brands.map((brand) => (
                            <Option key={brand.id} value={brand.id}>
                                {brand.brand_name}
                            </Option>
                        ))}
                    </Select>
                </Col>

                <Col span={16}>
                    <Input.Search
                        placeholder="Search Products"
                        onChange={handleSearch}
                        enterButton
                    />
                </Col>
            </Row>

            {/* Product List */}
            {loading && !filteredItems.length ? (
                <Spin />
            ) : (
                <List
                    itemLayout="vertical"
                    dataSource={filteredItems}
                    locale={{
                        emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No products found."
                            />
                        ),
                    }}
                    renderItem={(item) => {
                        const isAlreadyAdded = selectedProducts.some((product) => product.id === item.id);

                        return (
                            <div
                                key={item.id}
                                style={{
                                    borderBottom: '1px solid #f0f0f0',
                                    padding: '10px',
                                    marginBottom: 8,
                                    backgroundColor: isAlreadyAdded ? '#f6ffed' : '#fff',
                                }}
                            >
                                {/* Item Code and Brand */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                    <span>{item.item_code}</span>
                                    <span>{brands.find(b => b.id === item.brand)?.brand_name}</span>
                                </div>

                                {/* Item Name */}
                                <Text type="secondary" style={{ display: 'block', marginTop: 4 }}>
                                    {truncateText(item.item_name, 40)}
                                </Text>

                                {/* Price, Discount, and Add Button */}
                                <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Space>
                                        <Text strong>Price:</Text>
                                        <Text>{formatRupee(item.ticket_price)}</Text>
                                        {item.mrp && (
                                            <Text delete type="secondary">
                                                {formatRupee(item.mrp)}
                                            </Text>
                                        )}
                                        {item.mrp && item.ticket_price && (
                                            <Text type="success">
                                                {`(${calculateDiscountPercentage(parseFloat(item.mrp), parseFloat(item.ticket_price))}% off)`}
                                            </Text>
                                        )}
                                    </Space>
                                    {isAlreadyAdded ? (
                                        <Tag icon={<CheckCircleOutlined />} color="success">
                                            Added
                                        </Tag>
                                    ) : (
                                        <Button
                                            type="primary"
                                            shape="round"
                                            icon={<ShoppingCartOutlined />}
                                            onClick={() => handleAddProduct(item)}
                                            style={{ backgroundColor: '#4CAF50', color: '#fff', borderColor: '#4CAF50' }}
                                        >
                                            Add
                                        </Button>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                />
            )}
        </Drawer>
    );
};

export default ProductDrawer;
