import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Login from './features/auth/Login';
import Dashboard from './pages/Dashboard';
import LayoutWrapper from "./layouts/LayoutWrapper";
import StatesList from "./features/masters/StatesList";
import CountriesList from "./features/masters/CountriesList";
import SalesOrderList from "./features/sales/SalesOrderList";
import ProductList from "./features/products/ProductList";
import TransactionForm from "./features/products/TransactionForm";
import SalesPage from "./pages/SalesPage";
import CustomerRegistration from "./features/customers/CustomerRegistration";
import SalesAnalyticsDashboard from "./pages/SalesAnalyticsDashboard";
import Procurement from "./pages/ProcurementPage";
import InventoryPage from "./pages/InventoryPage";
import ProductCatalog from "./features/products/ProductCatalog";
import RefreshLocalStorage from "./pages/refreshLocalStorage";


const App = () => {
    const token = useSelector((state) => state.auth.token);
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);

    // Update `isAuthenticated` state when `token` changes
    useEffect(() => {
        setIsAuthenticated(!!token);
    }, [token]);

    // ProtectedRoute component to avoid navigation loops
    const ProtectedRoute = ({element}) => {
        if (!isAuthenticated) {
            return <Navigate to="/" replace/>;
        }
        return element;
    };


    return (
        <Router>
            <Routes>
                <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" replace/> : <Login/>}/>

                {/* Protected Routes - Wrapped in LayoutWrapper */}
                <Route element={<ProtectedRoute element={<LayoutWrapper/>}/>}>
                    <Route path="/sales-dashboard" element={<SalesAnalyticsDashboard/>}/>
                    <Route path="/sales" element={<SalesPage />} />
                    <Route path="/sales-orders" element={<SalesOrderList />} />
                    <Route path="/inventory" element={<InventoryPage/>} />
                    <Route path="/product-catalogue" element={<ProductCatalog/>} />
                    <Route path="/states_list" element={<StatesList />}/>
                    <Route path="/countries_list" element={<CountriesList/>}/>
                    <Route path="/register-customer" element={<CustomerRegistration />} />
                    <Route path="/procurement" element={<Procurement />} />

                    <Route path="/products" element={<ProductList />} />
                    <Route path="/refresh_local_storage" element={<RefreshLocalStorage />} />
                    <Route path="/transactions" element={<TransactionForm />} />
                    <Route path="*" element={<Navigate to="/dashboard"/>}/>
                </Route>
            </Routes>
        </Router>
    );

};

export default App;
