import React, {useState, useEffect} from 'react';
import {Button, message, Typography, Space} from 'antd';
import {PlusOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import CreateSaleOrder from '../features/sales/CreateSaleOrder';
import OrderSummary from '../features/sales/OrderSummary';
import CustomerDetails from '../features/sales/CustomerDetails';
import {createSaleOrderApi, updateSaleOrderApi} from '../features/sales/saleOrdersApi';
import SalesOrdersList from "../features/sales/SalesOrderList";
import SalespersonSelector from "../features/sales/SalespersonSelector";
import OrderReviewPage from "../features/sales/OrderReviewPage";
import CompanyBranchSelector from "../features/common/CompanyBranchSelector";
import {handleSubmitOrder} from "../features/sales/saleOrderActions";

const {Title} = Typography;

const SalesPage = () => {
    const [step, setStep] = useState('list'); // "list", "create", "review"
    const [editingCustomer, setEditingCustomer] = useState(false);
    const [editingSalesperson, setEditingSalesperson] = useState(false);
    const [editingCompanyBranch, setEditingCompanyBranch] = useState(false);
    const [editingOrderId, setEditingOrderId] = useState(null);


    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    // Fetch company and branch information from local storage
    // Fetch user details from localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    const companyId = user?.company_id || '';
    const companyName = user?.company || '';
    const companyCode = user?.company_code
    const branchId = user?.branch_id || '';
    const branchName = user?.branch || '';
    const branchCode = user?.branch_code || '';

    const [orderData, setOrderData] = useState({
        customer: null,
        selectedProducts: [],
        orderValue: 0,
        totalQuantity: 0,
        totalMRPValue: 0,
        isApproved: true,
        salesperson: null,
        company: {id: companyId, name: companyName, code: companyCode},
        branch: {id: branchId, name: branchName, code: branchCode},
    });
    const [productDrawerVisible, setProductDrawerVisible] = useState(false);

    // Calculate totals whenever products change
    useEffect(() => {
        const totalValue = orderData.selectedProducts.reduce((sum, item) => sum + item.item_price * item.quantity, 0);
        const totalMRP = orderData.selectedProducts.reduce((sum, item) => sum + item.mrp * item.quantity, 0);
        const totalQty = orderData.selectedProducts.reduce((sum, item) => sum + item.quantity, 0);
        const isBelowMOP = orderData.selectedProducts.some(item => item.item_price < item.mop);

        setOrderData(prev => ({
            ...prev, orderValue: totalValue, totalMRPValue: totalMRP, totalQuantity: totalQty, isApproved: !isBelowMOP,
        }));
    }, [orderData.selectedProducts]);

    // Navigation Handlers
    const handleBackToList = () => setStep('list');
    const handleReviewOrder = () => setStep('review');

    const handleEditOrderData = () => {
        // Directly set the step to 'create' without fetching data again
        setStep('create');
    };

    const handleAddNewOrder = () => {
        // Clear existing order data
        setOrderData({
            customer: null,
            selectedProducts: [],
            orderValue: 0,
            totalQuantity: 0,
            totalMRPValue: 0,
            isApproved: true,
            salesperson: null,
            company: {id: companyId, name: companyName, code: companyCode},
            branch: {id: branchId, name: branchName, code: branchCode},
        });
        setEditingOrderId(null); // Reset editing order ID
        setStep('create');
    };

    const handleAddProduct = (product) => {
        if (orderData.selectedProducts.some(item => item.item === product.id)) {
            setInfoMessage(`${product.item_code} is already added`);
            return;
        }
        // Add the product with all required fields
        const newProduct = {
            ...product,
            quantity: 1,
            item: product.id,
            item_price: product.ticket_price,  // Default to ticket price
            mrp: product.mrp || 0,
            mop: product.mop || 0,
            tax_group: product.tax_group || null,
            tax_percentage: product.tax_group?.tax_percentage || 0,
            hsn_code: product.hsn_code || null,
            ticket_price: product.ticket_price || 0
        };

        setOrderData(prev => ({
            ...prev, selectedProducts: [...prev.selectedProducts, newProduct]
        }));
    };

    const handleRemoveProduct = (id) => {
        setOrderData(prev => ({
            ...prev, selectedProducts: prev.selectedProducts.filter(item => item.id !== id)
        }));
    };


    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);


    const handleQuantityChange = (id, quantity) => {
        setOrderData(prev => ({
            ...prev, selectedProducts: prev.selectedProducts.map(item => item.id === id ? {...item, quantity} : item)
        }));
    };

    const handlePriceChange = (id, price) => {
        setOrderData(prev => ({
            ...prev,
            selectedProducts: prev.selectedProducts.map(item => item.id === id ? {...item, item_price: price} : item)
        }));
    };

    const handleCustomerChange = (updatedCustomer) => {
        setOrderData(prev => ({
            ...prev, customer: updatedCustomer
        }));
        setEditingCustomer(false); // Close the modal after updating
    };

    // Handlers
    const handleEditSalesperson = (selectedSalesperson) => {
        setOrderData(prev => ({
        ...prev,
        salesperson: {
            id: selectedSalesperson.id,
            full_name: selectedSalesperson.full_name,
        }
    }));
    setEditingSalesperson(false);
    };

    const handleEditCompanyBranch = (updatedCompanyBranch) => {
        setOrderData(prev => ({
            ...prev, company: updatedCompanyBranch.company, branch: updatedCompanyBranch.branch
        }));
        setEditingCompanyBranch(false);
    };

    // Handler for editing an existing order
    const handleEditOrder = async (passedOrderData = null) => {
        try {
            setErrorMessage(null); // Clear previous error messages

            // Use the passed order data if available, otherwise use the existing state
            const data = passedOrderData || orderData;

            // If no data is available, show an error
            if (!data) {
                setErrorMessage('Order data not found');
                return;
            }

            // Update state with the fetched or passed order data
            setOrderData({
                customer: {
                    id:data.customer_id,
                    name: data.customer_name,
                    mobile: data.mobile_number,
                },
                selectedProducts: data.items?.map(item => ({
                    id: item.id,
                    item: item.item,
                    item_code: item.item_code,
                    item_name: item.item_name,
                    item_price: item.item_price,
                    ticket_price: item.ticket_price,
                    quantity: item.quantity,
                    mrp: item.mrp,
                    mop: item.mop,
                    tax_group: item.tax_group,
                    tax_percentage: item.tax_percentage,
                    hsn_code: item.hsn_code,
                })) || [],
                salesperson: {
                id: data.salesperson_id,
                full_name: data.salesperson_name
            },
                company: {
                    id: data.company_id,
                    name: data.company_name,
                    code: data.company_code,
                },
                branch: {
                    id: data.branch_id,
                    name: data.branch_name,
                    code: data.branch_code,
                },
                totalQuantity: data.total_quantity || 0,
                orderValue: data.order_value || 0,
                totalMRPValue: data.total_mrp_value || 0,
                isApproved: data.is_approved || false,
            });

            setEditingOrderId(data.id);
            setStep('create');
        } catch (error) {
            setStep('list');
            setErrorMessage('Failed to load sale order for editing');
        }
    };


    return (<div style={{padding: 0}}>
        {contextHolder}
        {step === 'create' && (<>
            {/* Back Button */}
            <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px'
            }}>
                <Title level={4}>Create Sales Order</Title>
                <Button
                    icon={<ArrowLeftOutlined/>}
                    onClick={handleBackToList}
                    type="default"
                >
                    Back to Orders
                </Button>
            </div>

            {/* Order Summary */}
            <OrderSummary
                customer={orderData.customer}
                company={orderData.company}
                branch={orderData.branch}
                totalQuantity={orderData.totalQuantity}
                orderValue={orderData.orderValue}
                totalMRPValue={orderData.totalMRPValue}
                isApproved={orderData.isApproved}
                salesperson={orderData.salesperson}
                onEditCustomer={() => setEditingCustomer(true)}
                onEditSalesperson={() => setEditingSalesperson(true)}
                onEditCompanyBranch={() => setEditingCompanyBranch(true)}
            />


            {/* Create Sale Order */}
            <CreateSaleOrder
                selectedProducts={orderData.selectedProducts}
                onAddProduct={handleAddProduct}
                onRemoveProduct={handleRemoveProduct}
                onQuantityChange={handleQuantityChange}
                onPriceChange={handlePriceChange}
                onReviewOrder={handleReviewOrder}
                productDrawerVisible={productDrawerVisible}
                setProductDrawerVisible={setProductDrawerVisible}
                setInfoMessage={setInfoMessage}
            />
        </>)}

        {/* Customer Details Modal */}
        <CustomerDetails
            visible={editingCustomer}
            initialData={orderData.customer}
            onCustomerChange={handleCustomerChange}
            onCancel={() => setEditingCustomer(false)}
            setInfoMessage={setInfoMessage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
        />

        <SalespersonSelector
            visible={editingSalesperson}
            onClose={() => setEditingSalesperson(false)}
            currentSalesperson={orderData.salesperson}
            onSelectSalesperson={handleEditSalesperson}
        />

        <CompanyBranchSelector
            visible={editingCompanyBranch}
            currentCompany={orderData.company}
            currentBranch={orderData.branch}
            onSelectCompanyBranch={handleEditCompanyBranch}
            onClose={() => setEditingCompanyBranch(false)}
        />




        {step === 'review' && (<OrderReviewPage
            orderData={orderData}
            onSubmitOrder={() => handleSubmitOrder(orderData, editingOrderId, handleBackToList, setSuccessMessage, setErrorMessage)}
            handleEditOrderData={handleEditOrderData}
            onEditCustomer={() => setEditingCustomer(true)}
            onEditSalesperson={() => setEditingSalesperson(true)}
            onEditCompanyBranch={() => setEditingCompanyBranch(true)}
        />)}

        {step === 'list' && (<>
            {/* Sales Orders List */}
            <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px'
            }}>
                <Title level={4}>Sales Orders List</Title>
                <Button type="primary" icon={<PlusOutlined/>} onClick={handleAddNewOrder}>
                    Add New Sale Order
                </Button>
            </div>
            <SalesOrdersList
                onEditOrder={handleEditOrder}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
            />
        </>)}
    </div>);
};

export default SalesPage;
