// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import productReducer from '../features/products/productSlice';
import companyBranchReducer from "./slices/companyBranchSlice";
import configurationReducer from './slices/locationSlice';
import employeeReducer from './slices/employeeSlice';
// import salesReducer from '../features/sales/salesSlice';
// import procurementReducer from '../features/procurement/procurementSlice';
// import inventoryReducer from '../features/inventory/inventorySlice';
// import expensesReducer from '../features/expenses/expensesSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        products: productReducer,
        companyBranch: companyBranchReducer,
        configuration: configurationReducer,
        employees: employeeReducer,
        // sales: salesReducer,
        // procurement: procurementReducer,
        // inventory: inventoryReducer,
        // expenses: expensesReducer,
    },
});

export default store;
