import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';

const ITEMS_API_URL = `${config.inxOmsApiUrl}/inventory/api/items/`;
const BRANDS_API_URL = `${config.inxOmsApiUrl}/configurations/api/brands/`;


// Improved fetchItems thunk
export const fetchItemsApi = createAsyncThunk(
    'products/fetchItems',
    async (url = ITEMS_API_URL, { dispatch, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('token'); // Consider securing token storage further
            const response = await axios.get(url, {
                headers: { Authorization: `Token ${token}` },
            });

            let results = response.data.results;

            console.log("Items: ", response.data);  // Debugging log, remove for production

            if (response.data.next) {
                console.log("Next URL: ", response.data.next);  // Debugging log, remove for production
                const nextResults = await dispatch(fetchItemsApi(response.data.next)).unwrap();
                results = results.concat(nextResults);
            }

            return results;
        } catch (error) {
            console.error('Fetch failed:', error); // Debugging log, handle errors as needed
            return rejectWithValue(error.response.data);
        }
    }
);

// Refresh Items Thunk
export const refreshItems = createAsyncThunk(
    'products/refreshItems',
    async (_, { dispatch }) => {
        localStorage.removeItem('allItems'); // Clear items from local storage
        return dispatch(fetchItemsApi()); // Re-fetch items
    }
);

// Thunk to fetch brands
export const fetchBrandsApi = createAsyncThunk(
    'products/fetchBrands',
    async (_, { getState }) => {
        const state = getState().products;

        // Check if brands are already available in state
        if (state.hasFetchedBrands && state.brands.length > 0) {
            return state.brands;
        }

        // Check localStorage
        const storedBrands = localStorage.getItem('brands');
        if (storedBrands) {
            try {
                const parsedBrands = JSON.parse(storedBrands);
                if (Array.isArray(parsedBrands) && parsedBrands.length > 0) {
                    return parsedBrands;
                }
            } catch (error) {
                console.error('Error parsing brands from localStorage', error);
            }
        }

        // Fetch from API if not found in state or valid in localStorage
        const token = localStorage.getItem('token');
        const response = await axios.get(BRANDS_API_URL, {
            headers: { Authorization: `Token ${token}` },
        });

        // Save to localStorage
        localStorage.setItem('brands', JSON.stringify(response.data));

        return response.data;
    }
);



const productSlice = createSlice({
    name: 'products',
    initialState: {
        allItems: JSON.parse(localStorage.getItem('allItems')) || [],
        brands: [],
        loading: false,
        brandsLoading: false,
        error: null,
        hasFetched: !!localStorage.getItem('allItems'),
        hasFetchedBrands: false,
    },
    reducers: {
        clearProducts: (state) => {
            state.allItems = [];
            state.brands = [];
            state.hasFetched = false;
            state.hasFetchedBrands = false;
            localStorage.removeItem('allItems');
        },
    },
    extraReducers: (builder) => {
        // Handle fetchItemsApi cases
        builder
            .addCase(fetchItemsApi.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchItemsApi.fulfilled, (state, action) => {
                const fetchedItems = action.payload;

                // Prevent duplicates by filtering out already existing items
                const newItems = fetchedItems.filter(
                    (newItem) => !state.allItems.some((existingItem) => existingItem.id === newItem.id)
                );

                // Append new items to the state
                state.allItems = [...state.allItems, ...newItems];

                // Store in localStorage for persistence
                localStorage.setItem('allItems', JSON.stringify(state.allItems));

                state.hasFetched = true;
                state.loading = false;
            })
            .addCase(fetchItemsApi.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

        // Handle fetchBrandsApi cases
        builder
            .addCase(fetchBrandsApi.pending, (state) => {
                state.brandsLoading = true;
                state.error = null;
            })
            .addCase(fetchBrandsApi.fulfilled, (state, action) => {
                state.brandsLoading = false;
                state.brands = action.payload;
                state.hasFetchedBrands = true;
            })
            .addCase(fetchBrandsApi.rejected, (state, action) => {
                state.brandsLoading = false;
                state.error = action.error.message;
            });
    },
});

export const {clearProducts} = productSlice.actions;
export default productSlice.reducer;
