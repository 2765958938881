// src/components/CountryFormModal.js
import React, {useEffect, useState} from 'react';
import { Modal, Form, Input, message } from 'antd';

const CountryFormModal = ({ visible, onCancel, onSubmit, country = null }) => {
    const [form] = Form.useForm();
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (country) {
            form.setFieldsValue(country);
        } else {
            form.resetFields();
        }
    }, [country, form]);

    const handleFinish = async (values) => {
        try {
            await onSubmit(values);
            setSuccessMessage(country ? 'Country updated successfully' : 'Country added successfully');
            form.resetFields();
            onCancel();
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    useEffect(() => {
        // Display success or error messages
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
    }, [successMessage, errorMessage, messageApi]);

    return (
        <>
            {contextHolder}
        <Modal
            open={visible}
            title={country ? 'Edit Country' : 'Add Country'}
            onCancel={onCancel}
            onOk={() => form.submit()}
            okText={country ? 'Update' : 'Create'}
            cancelText={'Cancel'}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item
                    name="country_name"
                    label="Country Name"
                    rules={[{ required: true, message: 'Please enter the country name' }]}
                >
                    <Input placeholder="Enter country name" />
                </Form.Item>
                <Form.Item
                    name="country_code"
                    label="Country Code"
                    rules={[{ required: true, message: 'Please enter the country code' }]}
                >
                    <Input placeholder="Enter country code" />
                </Form.Item>
                <Form.Item
                    name="isd_code"
                    label="ISD Code"
                    rules={[{ required: true, message: 'Please enter the ISD code' }]}
                >
                    <Input placeholder="Enter ISD code" />
                </Form.Item>
            </Form>
        </Modal>
            </>
    );
};

export default CountryFormModal;
