import React from 'react';
import {Table, Tooltip} from 'antd';
import {formatRupee, truncateText} from "../../utils/utils";

const PivotTable = ({data}) => {
    // Step 1: Extract unique statuses using status_display
    const uniqueStatuses = [...new Set(data.map((item) => item.status_display))];

    // Step 2: Transform data into a pivot-like structure
    const transformedData = data.reduce((acc, item) => {
        const rowKey = `${item.branch_name} - ${item.company_name}`;

        if (!acc[rowKey]) {
            acc[rowKey] = {
                key: rowKey,
                branch_name: item.branch_name,
                company_name: item.company_name,
                company_code: item.company_code,
                branch_code: item.branch_code,
                branch_id: item.branch_id,
                company_id: item.company_id,
            };
        }

        // Use status_display instead of status for the key
        acc[rowKey][item.status_display] = `(${item.order_count}) ${formatRupee(item.total_order_value)}`;
        return acc;
    }, {});

    // Convert transformed object into an array for the table data source
    const dataSource = Object.values(transformedData);

    // Step 3: Define dynamic columns based on unique status_display values
    const columns = [
        {
            title: 'Branch & Company',
            dataIndex: 'branch_name',
            key: 'branch_name',
            ellipsis: true,
            render: (_, record) => {
                // Safely handle cases where branch_name or company_code might be missing
                const branchName = record.branch_name || 'N/A'; // Default to 'N/A' if branch_name is null/undefined
                const branchCode = record.branch_code || 'N/A'; // Default to 'N/A' if branch_name is null/undefined
                const companyName = record.company_name || 'N/A'; // Default to 'N/A' if company_code is null/undefined
                const companyCode = record.company_code || 'N/A'; // Default to 'N/A' if company_code is null/undefined

                return (
                    <Tooltip title={`${branchName} - (${companyName})`}>
        <span>
          {truncateText(branchCode, 15)} - ({companyCode})
        </span>
                    </Tooltip>
                );
            },

        },
        ...uniqueStatuses.map((statusDisplay) => ({
            title: statusDisplay,
            dataIndex: statusDisplay,
            key: statusDisplay,
            render: (value) => (
                <div
                    style={{
                        textAlign: value && value !== '-' ? 'right' : 'center',
                        fontWeight: value && value !== '-' ? 'bold' : 'normal',
                    }}
                >
                    {value ? value : '-'}
                </div>
            ),
        })),
    ];

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
            size="small"
        />
    );
};

export default PivotTable;
