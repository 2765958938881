import React, {useEffect, useState} from 'react';
import {Button, Input, Select, Spin, Tag} from 'antd';
import SalesOrdersTable from './SalesOrdersTable';
import SaleOrderDetailsDrawer from './SaleOrderDetailsDrawer';
import {fetchSaleOrdersApi} from './saleOrdersApi';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCompanies} from '../../store/slices/companyBranchSlice';
import {ClearOutlined, FilterOutlined, NumberOutlined, PhoneOutlined, UserOutlined} from "@ant-design/icons";

const {Search} = Input;
const {Option} = Select;

const SalesOrdersList = ({onEditOrder, setErrorMessage, setSuccessMessage}) => {
    const dispatch = useDispatch();
    const {companies, loading: companiesLoading} = useSelector((state) => state.companyBranch);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [searchType, setSearchType] = useState('order_number');

    // Filters state
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');

    // Temporary state for filters
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const [tempSelectedStatus, setTempSelectedStatus] = useState('');
    const [tempSelectedCompany, setTempSelectedCompany] = useState('');
    const [tempSelectedBranch, setTempSelectedBranch] = useState('');

    const [branches, setBranches] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);

    // Fetch companies on component mount
    useEffect(() => {
        if (!companies || companies.length === 0) {
            dispatch(fetchCompanies());
        }
    }, [dispatch, companies]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === tempSelectedCompany);
        setBranches(company ? company.branches : []);
    }, [tempSelectedCompany, companies]);

    // Fetch orders from the API
    const fetchOrders = async (page = 1) => {
        setLoading(true);
        let mobileNumber = '';
        let customerName = '';
        let orderNumber = '';

        if (searchType === 'mobile_number') {
            mobileNumber = searchTerm.trim();
        } else if (searchType === 'order_number') {
            orderNumber = searchTerm.trim();
        } else {
            customerName = searchTerm.trim();
        }
        try {
            const data = await fetchSaleOrdersApi(page,
                selectedStatus,
                selectedCompany,
                selectedBranch,
                mobileNumber,
                customerName,
                orderNumber
            );
            setOrders(data.results);
            setPagination((prev) => ({
                ...prev,
                total: data.count,
                current: page,
            }));
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch orders whenever filters or pagination change
    useEffect(() => {
        fetchOrders(pagination.current);
    }, [pagination.current, searchTerm, selectedStatus, selectedCompany, selectedBranch]);

    // Handle applying filters
    const handleApplyFilters = () => {
        setSearchTerm(tempSearchTerm);
        setSelectedStatus(tempSelectedStatus);
        setSelectedCompany(tempSelectedCompany);
        setSelectedBranch(tempSelectedBranch);
        setPagination({...pagination, current: 1});
    };

    // Handle clearing filters
    const handleClearFilters = () => {
        if (!tempSearchTerm && !tempSelectedStatus && !tempSelectedCompany && !tempSelectedBranch) return;

        setTempSearchTerm('');
        setTempSelectedStatus('');
        setTempSelectedCompany('');
        setTempSelectedBranch('');

        setSearchTerm('');
        setSelectedStatus('');
        setSelectedCompany('');
        setSelectedBranch('');

        // Reset pagination and fetch data
        setPagination({current: 1, pageSize: 10});
        fetchOrders(1);
    };

    const handleTableChange = (pagination) => {
        setPagination({current: pagination.current, pageSize: pagination.pageSize});
    };

    const handleRowClick = (record) => {
        setSelectedOrderId(record.id);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedOrderId(null);
    };

    const searchOptions = [
        {value: 'order_number', label: 'Order Number', icon: <NumberOutlined/>},
        {value: 'customer_name', label: 'Customer Name', icon: <UserOutlined/>},
        {value: 'mobile_number', label: 'Mobile Number', icon: <PhoneOutlined/>},
    ];

    const selectBefore = (
        <Select
            defaultValue="order_number"
            onChange={(value) => setSearchType(value)}
            style={{width: "auto"}}
        >
            {searchOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                    {option.icon}
                </Option>
            ))}
        </Select>
    );

    // Dynamically get the label for the placeholder
    const placeholderLabel = searchOptions.find(option => option.value === searchType)?.label || 'Search';

    const statusDisplayMap = {
    draft: 'Draft',
    awaiting_approval: 'Awaiting Approval',
    ready_to_execute: 'Ready to Execute',
    executed: 'Executed',
    ready_to_invoice: 'Ready to Invoice',
    ready_to_dispatch: 'Ready to Dispatch',
    dispatched: 'Dispatched',
    closed: 'Closed',
    cancelled: 'Cancelled',
    rejected: 'Rejected',
};

    return (
        <>
            {/* Search and Filters */}
            <div style={{display: 'flex', gap: '16px', marginBottom: '16px'}}>
                <Search
                    addonBefore={selectBefore}
                    placeholder={`Search by ${placeholderLabel}`}
                    value={tempSearchTerm}
                    onChange={(e) => setTempSearchTerm(e.target.value)}
                    enterButton={<Button type="primary" icon={<FilterOutlined/>}></Button>}
                    style={{width: 400}}
                    onSearch={handleApplyFilters}
                />

                <Select
                    placeholder="Filter by Status"
                    value={tempSelectedStatus || undefined}
                    onChange={(value) => setTempSelectedStatus(value)}
                    allowClear
                    style={{width: '200px'}}
                >
                    {Object.entries(statusDisplayMap).map(([key, label]) => (
                        <Option key={key} value={key}>
                            {label}
                        </Option>
                    ))}
                </Select>

                <Select
                    placeholder="Select Company"
                    value={tempSelectedCompany || undefined}
                    onChange={(value) => setTempSelectedCompany(value)}
                    style={{width: '200px'}}
                    allowClear
                >
                    {companies.map((company) => (
                        <Option key={company.id} value={company.id}>
                            {company.company_name}
                        </Option>
                    ))}
                </Select>

                <Select
                    placeholder="Select Branch"
                    value={tempSelectedBranch || undefined}
                    onChange={(value) => setTempSelectedBranch(value)}
                    style={{width: '200px'}}
                    disabled={!tempSelectedCompany}
                    allowClear
                >
                    {branches.map((branch) => (
                        <Option key={branch.id} value={branch.id}>
                            {branch.branch_name}
                        </Option>
                    ))}
                </Select>

                <Button
                    type="primary"
                    icon={<FilterOutlined/>}
                    onClick={handleApplyFilters}
                    style={{
                        backgroundColor: '#1890ff',
                        borderColor: '#1890ff',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    Apply Filters
                </Button>
                <Button
                    icon={<ClearOutlined/>}
                    onClick={handleClearFilters}
                    style={{
                        backgroundColor: '#f5222d',
                        borderColor: '#f5222d',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        marginLeft: '10px',
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            {/* Applied Filters */}
            <div style={{marginBottom: '16px'}}>
                {/* Conditionally render the search term with the corresponding label */}
                {searchTerm && (
                    <Tag>
                        {searchType === 'order_number' && `Order Number: ${searchTerm}`}
                        {searchType === 'customer_name' && `Customer Name: ${searchTerm}`}
                        {searchType === 'mobile_number' && `Mobile Number: ${searchTerm}`}
                    </Tag>
                )}
                {selectedStatus && (
                    <Tag>
                        Status: {statusDisplayMap[selectedStatus] || selectedStatus}
                    </Tag>
                )}
                {selectedCompany && (
                    <Tag>
                        Company: {companies.find((company) => company.id === selectedCompany)?.company_name || selectedCompany}
                    </Tag>
                )}
                {selectedBranch && (
                    <Tag>
                        Branch: {branches.find((branch) => branch.id === selectedBranch)?.branch_name || selectedBranch}
                    </Tag>
                )}
            </div>


            {/* Table */}
            <SalesOrdersTable
                data={orders}
                loading={loading}
                pagination={pagination}
                onTableChange={handleTableChange}
                onRowClick={handleRowClick}
                size="small"
            />

            <SaleOrderDetailsDrawer
                visible={drawerVisible}
                orderId={selectedOrderId}
                onClose={closeDrawer}
                onEditOrder={onEditOrder}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                refreshOrders={fetchOrders}
            />
        </>
    );
};

export default SalesOrdersList;