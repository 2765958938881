import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Typography, Modal, Table, Spin } from 'antd';
import { searchCustomerApi } from '../customers/customerApi';
import { truncateText } from '../../utils/utils';

const { Text } = Typography;

const CustomerDetails = ({
    visible,
    initialData,
    onCustomerChange,
    onCancel,
    setSuccessMessage,
    setErrorMessage,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Reset form fields when the modal is opened or initialData changes
    useEffect(() => {
        if (visible) {
            form.resetFields();
            if (initialData) {
                form.setFieldsValue(initialData);
            }
            setSelectedCustomer(null);
            setSearchResults([]);
        }
    }, [visible, initialData, form]);

    // Function to handle customer search
    const handleSearch = async (value) => {
        if (value.length < 4) {
            setSearchResults([]);
            return;
        }
        setSearchLoading(true);
        try {
            const results = await searchCustomerApi(value);
            setSearchResults(results);
        } catch (error) {
            setErrorMessage('Failed to search for customers');
        } finally {
            setSearchLoading(false);
        }
    };

    // Function to handle customer selection from search results
    const handleSelectCustomer = (customer) => {
        setSelectedCustomer(customer);
        form.setFieldsValue({
            id: customer.id,
            name: customer.name,
            mobile: customer.mobile_number,
        });
        setSearchResults([]);
        setSuccessMessage('Customer selected successfully');
    };

    // Function to handle form submission
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();

            // If a customer was selected from the search results, use that data
            const customerData = selectedCustomer
                ? {
                      id: selectedCustomer.id,
                      name: selectedCustomer.name,
                      mobile: selectedCustomer.mobile_number,
                  }
                : values;

            onCustomerChange(customerData);
            setSuccessMessage('Customer details updated successfully');
            onCancel(); // Close the modal after saving
        } catch (error) {
            setErrorMessage('Please fill in all required fields');
        } finally {
            setLoading(false);
        }
    };

    // Table columns for search results with text truncation
    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id', width: 70 },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span>{truncateText(text, 20)}</span>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            render: (text) => <span>{truncateText(text, 15)}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <span>{truncateText(text, 30)}</span>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, customer) => (
                <Button
                    type="link"
                    onClick={() => handleSelectCustomer(customer)}
                    disabled={selectedCustomer?.id === customer.id}
                >
                    Select
                </Button>
            ),
        },
    ];

    return (
        <Modal
            open={visible}
            title="Customer Details"
            onCancel={onCancel}
            onOk={handleSubmit}
            confirmLoading={loading}
            okText="Save Details"
            cancelText="Cancel"
            centered={false}
            destroyOnClose
            width={800}// Ensuring the modal is wide enough to fit the table
            style={{ top: 40 }}
        >
            {/* Instruction for searching */}
            <Text type="secondary" style={{ display: 'block', marginBottom: '16px' }}>
                Please search for a customer using their mobile number (minimum 4 digits).
            </Text>

            <Form
                form={form}
                layout="vertical"
                initialValues={initialData}
                style={{
                    maxWidth: '600px',
                    margin: '0 auto',
                    padding: '20px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                }}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={<Text strong>Customer Name</Text>}
                            name="name"
                            rules={[{ required: true, message: 'Please enter customer name' }]}
                        >
                            <Input placeholder="Enter customer name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={<Text strong>Mobile Number</Text>}
                            name="mobile"
                            rules={[
                                { required: true, message: 'Please enter mobile number' },
                                { pattern: /^[0-9]{10}$/, message: 'Enter a valid 10-digit mobile number' },
                            ]}
                        >
                            <Input
                                placeholder="Search by mobile number"
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    {selectedCustomer && (
                        <Col span={24}>
                            <Text strong>ID of Selected Customer:</Text> {selectedCustomer.id}
                        </Col>
                    )}
                </Row>
            </Form>

            {/* Search Results Table */}
            {searchLoading ? (
                <Spin style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                searchResults.length > 0 && (
                    <Table
                        columns={columns}
                        dataSource={searchResults}
                        rowKey="id"
                        size="small"
                        pagination={false}
                        style={{ marginTop: '20px' }}
                        locale={{
                            emptyText: 'No customers found.',
                        }}
                    />
                )
            )}
        </Modal>
    );
};

export default CustomerDetails;
