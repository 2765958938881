import React from 'react';
import {Empty, Table, Tooltip} from 'antd';
import {formatRupee, truncateText} from '../../utils/utils';
import {formatDate} from '../../utils/dateUtils';

const SalesOrdersTable = ({data, loading, pagination, onTableChange, onRowClick, size}) => {
    const columns = [
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            key: 'order_number',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 18)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'order_date',
            key: 'order_date',
            align: 'center',
            render: (value) => (
                <span style={{whiteSpace: 'nowrap'}}>{value ? formatDate(value) : '-'}</span>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_name',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status_display',
            key: 'status_display',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{text || '-'}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Order Value',
            dataIndex: 'order_value',
            key: 'order_value',
            align: 'right',
            render: (value) => (
                <span style={{whiteSpace: 'nowrap'}}>{value ? formatRupee(value) : '-'}</span>
            ),
        },
        {
            title: 'Total Quantity',
            dataIndex: 'total_quantity',
            key: 'total_quantity',
            align: 'center',
            render: (value) => <span>{value ?? '-'}</span>,
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            align: 'center',
            render: (value) => (
                <span style={{whiteSpace: 'nowrap'}}>{value ? formatDate(value) : '-'}</span>
            ),
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{textAlign: text ? 'left' : 'center', whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Invoice Amount',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            render: (value) => (
                <span style={{textAlign: value ? 'right' : 'center', whiteSpace: 'nowrap'}}>{value ? formatRupee(value) : '-'}</span>
            ),
        },
        {
            title: 'Salesperson',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            align: 'center',
            render: (text, record) => (
        <Tooltip title={text || '-'}>
            {text ? (
                <>
                    <span style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
                        {truncateText(text, 15)}
                        {record.salesperson_company_code && (
                            <span style={{ color: 'gray' }}> ({record.salesperson_company_code})</span>
                        )}
                    </span>
                </>
            ) : (
                <span style={{ textAlign: 'center' }}>-</span>
            )}
        </Tooltip>
    ),
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{textAlign: text ? 'left' : 'center', whiteSpace: 'nowrap'}}>{truncateText(text || '-', 15)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Company',
            dataIndex: 'company_code',
            key: 'company_code',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 15)}</span>
                </Tooltip>
            ),
        },
    ];

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} orders`;
    };

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                // showSizeChanger: true,
                // pageSizeOptions: ['10', '20', '50', '100'],
            }}
            onChange={onTableChange}
            onRow={(record) => ({
                onClick: () => onRowClick(record),
            })}
            size={size}
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No orders available."
                    />
                ),
            }}
        />
    );
};

export default SalesOrdersTable;
