// Convert number to Indian Rupees format with lakh/crore separators
export const formatRupee = (amount) => {
  if (amount === null || amount === undefined) return '';

  // Convert amount to a fixed 2 decimal places number
  const formattedAmount = parseFloat(amount).toFixed(2);

  // Split the integer and decimal parts
  const [integerPart, decimalPart] = formattedAmount.split('.');

  // Format integer part according to the Indian numbering system
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  const formattedInteger =
    otherDigits !== '' ? otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThreeDigits : lastThreeDigits;

  return `₹${formattedInteger}.${decimalPart}`;
};


export const calculateDiscountPercentage = (mrp, ticketPrice) => {
  const parsedMrp = parseFloat(mrp);
  const parsedTicketPrice = parseFloat(ticketPrice);

  if (isNaN(parsedMrp) || parsedMrp <= 0) {
    // console.error("Invalid MRP: Must be a positive number. Received:", mrp);
    return null;
  }
  if (isNaN(parsedTicketPrice) || parsedTicketPrice <= 0) {
    // console.error("Invalid Ticket Price: Must be a positive number. Received:", ticketPrice);
    return null;
  }

  const tolerance = 0.00001;
  if (parsedMrp <= parsedTicketPrice + tolerance) {
    // console.warn("No discount applicable: MRP is less than or equal to Ticket Price by tolerance.");
    return null;
  }

  const discount = ((parsedMrp - parsedTicketPrice) / parsedMrp) * 100;
  // console.log("discount", discount);
  return discount.toFixed(2);
};





// Truncate text to a specific length with ellipsis
export const truncateText = (text, maxLength = 30) => {
  if (!text) return '';
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};